import {Action} from '@ngrx/store';
import {Location} from '../models/location.model';

export enum LocationActionTypes {
  LoadLocations = '[Location] Load Locations',
  LoadLocationsSuccess = '[Location] Load Locations Success',
  LoadLocationsError = '[Location] Load Locations Error',
}

export class LoadLocations implements Action {
  readonly type = LocationActionTypes.LoadLocations;

  constructor() {}
}

export class LoadLocationsSuccess implements Action {
  readonly type = LocationActionTypes.LoadLocationsSuccess;

  constructor(public payload: { locations: any }) {}
}

export class LoadLocationsError implements Action {
  readonly type = LocationActionTypes.LoadLocationsError;

  constructor() {}
}

export type LocationActions =
  LoadLocations
  | LoadLocationsSuccess
  | LoadLocationsError;
