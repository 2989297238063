import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Type} from '../../models/type.model';

@Component({
  selector: 'app-result-graph',
  templateUrl: './result-graph.component.html',
  styleUrls: ['./result-graph.component.scss']
})
export class ResultGraphComponent implements OnInit, OnChanges {
  @Input() results: Type[];
  @ViewChild('bar') bar: ElementRef;

  constructor() {
  }

  ngOnInit() {
  }

  calculatePercentage() {
    let totalNumber = 0;
    this.results.forEach((result) => totalNumber += result.count);
    this.results.map((result) => {
      result.countPercentage = Math.round((100 / totalNumber) * result.count);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.results.currentValue) {
      this.calculatePercentage();
    }
  }
}
