import {Component, Input} from '@angular/core';

/**
 * Generated class for the CountDownComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.html',
  styleUrls: ['./count-down.scss']
})
export class CountDownComponent {
  @Input('countdown') countdown;

  constructor() {
  }
}
