<ion-content>
  <div class="content-wrapper" *ngIf="hasCounted === true">
    <div>
      <h2 class="ion-no-margin ion-margin-bottom">Voila, jouw resultaat!</h2>

      <p>Dit is de score van jouw telling.</p>

      <ion-spinner *ngIf="loading"></ion-spinner>

      <spread *ngIf="!loading && modalShift" [fill]="modalShift.car"></spread>

      <p *ngIf="!loading">Het verkeer dat je telde bestaat voor {{modalShift.car || 50}}% uit wagens,
        vrachtwagens en bestelwagens en voor {{modalShift.public || 50}}% uit voetgangers,
        fietsers en het openbaar vervoer.</p>

      <app-result-graph [results]="types"></app-result-graph>
    </div>

    <div class="ion-padding-bottom">
      <p>
        Neem nog even de tijd een korte vragenlijst in verband met jouw telervaring te beantwoorden.
      </p>
      <ion-button expand="block"
                  (click)="goToExternal('https://nl.surveymonkey.com/r/3DNGZFB')">
        Naar de enquête
      </ion-button>
    </div>
  </div>
</ion-content>