import {Injectable} from '@angular/core';
import {filter, map, tap} from 'rxjs/operators';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private adminDoc: AngularFirestoreDocument<any>;
  private exists: ReplaySubject<boolean> = new ReplaySubject();

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore) {
    this.afAuth.user
      .pipe(filter((user) => !!user))
      .subscribe((user) => {
        this.adminDoc = afs.doc<any>(`admins/${user.uid}`);

        this.adminDoc.snapshotChanges().subscribe((admin) => this.exists.next(admin.payload.exists));
      });
  }

  isAdmin(): Observable<boolean> {
    return this.exists;
  }

  getNotifications() {
    return this.afs.collection<any>(`notifications`, ref => ref.where('send', '==', false))
      .snapshotChanges()
      .pipe((map((changes) => {
        return changes.map(c => ({id: c.payload.doc.id, ...c.payload.doc.data()}));
      })));
  }

  createNotifications(data) {
    return this.afs.collection<any>(`notifications`)
      .add({
        ...data,
        date: new Date(data.date),
        send: false,
      });
  }

  removeNotification(notificationId) {
    return this.afs.doc<any>(`notifications/${notificationId}`).delete();
  }
}
