<ion-content>
  <div class="wrapper">
    <count-progress-bar [progress]="progress"></count-progress-bar>

    <div class="count">
      <type-count *ngFor="let type of types;trackBy:identify"
                  [name]="type.name"
                  [slug]="type.slug"
                  [id]="type.id"
                  [color]="type.color"
                  [count]="count[type.slug]"
                  (add)="addToCount($event)"
                  (remove)="removeFromCount($event)">
      </type-count>
    </div>
  </div>
</ion-content>