<ion-grid class="ion-no-padding">
  <ion-row class="ion-justify-content-between ion-align-items-center">
    <ion-col size="5">
      <div class="left ion-text-left" >
        {{fill}}%
      </div>
    </ion-col>
    <ion-col size="2">
      <div class="middle">vs</div>
    </ion-col>
    <ion-col size="5">
      <div class="right ion-text-right">
        {{100 - fill }}%
      </div>
    </ion-col>
  </ion-row>
</ion-grid>

<div class="spreadbar">
  <div class="separator"></div>
  <div class="spreadbar-fill" [style.width]="(fill | min:0 | max:100) + '%'"></div>
</div>

<div class="images">
  <img [src]="'/assets/images/spread-cars.svg'">
  <img [src]="'/assets/images/spread-public.svg'">
</div>
