import {Action} from '@ngrx/store';
import {Questionnaire} from '../models/questionnaire.model';

export enum QuestionnaireActionTypes {
  LoadQuestionnaire = '[Questionnaire] Load Questionnaires',
  UpdateQuestionnaire = '[Questionnaire] Update Questionnaires',
}

export class LoadQuestionnaires implements Action {
  readonly type = QuestionnaireActionTypes.LoadQuestionnaire;
}

export class UpdateQuestionnaire implements Action {
  readonly type = QuestionnaireActionTypes.UpdateQuestionnaire;

  constructor(public payload: Partial<Questionnaire>) {
  }
}


export type QuestionnaireActions =
  LoadQuestionnaires |
  UpdateQuestionnaire;

