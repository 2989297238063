<ion-content>
  <div class="heading">
    <h3>Legende</h3>

    <ion-buttons>
      <ion-button (click)="dismissPopover()"
                  [color]="'dark'">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </div>

  <ion-list lines="none">
    <ion-item>
      <img [src]="'assets/images/types/truck.svg'" alt=""/>
      <p>alle types vrachtwagens, tractoren, graafmachines...</p>
    </ion-item>
    <ion-item>
      <img [src]="'assets/images/types/bus.svg'" alt=""/>
      <p>bus en tram</p>
    </ion-item>
    <ion-item>
      <img [src]="'assets/images/types/deliverytruck.svg'" alt=""/>
      <p>kleine bestelwagen en minibus</p>
    </ion-item>
    <ion-item>
      <img [src]="'assets/images/types/car.svg'" alt=""/>
      <p>auto, zware motor, lichte bromfiets, scooter…</p>
    </ion-item>
    <ion-item>
      <img [src]="'assets/images/types/bike.svg'" alt=""/>
      <p>fiets, elektrische fiets/pedelec, tandem, bakfiets, (e-)step, skateboard, hooverboard, rolschaatsen, scootmobiel, ruiter op paard...</p>
    </ion-item>
    <ion-item>
      <img [src]="'assets/images/types/pedestrian.svg'" alt=""/>
      <p>voetganger, rolstoel, kinderwagen…</p>
    </ion-item>
  </ion-list>
</ion-content>