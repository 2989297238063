import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromStore from '../../reducers';
import {selectCurrentCountMoment} from '../../reducers';
import {combineLatest, interval, Observable, Subscription} from 'rxjs';
import {filter, map, startWith, take} from 'rxjs/operators';
import {CountMomentService} from '../../services/count-moment.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import * as Sentry from '@sentry/browser';
import {SetActiveCountMoment} from '../../actions/count-moment.actions';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit {
  currentCountMoment$ = this.store.pipe(select(selectCurrentCountMoment));
  status: string;
  name: string;
  startTime: string;
  endTime: string;
  cityId: number;
  countdown: moment.Duration;
  private hasCountedSubscription: Subscription;
  hasCounted: boolean;

  private statusSubscription: Subscription;

  constructor(
    private store: Store<fromStore.State>,
    private countMomentService: CountMomentService,
    private router: Router,
  ) {
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.countMomentService.getActive().pipe(take(1)).subscribe(countMoment => {
      this.store.dispatch(new SetActiveCountMoment({id: countMoment.id, future: true}));
    });

    this.statusSubscription = combineLatest([
      this.currentCountMoment$,
      interval(250).pipe(startWith(null)),
    ]).pipe(filter(([currentCountMoment]) => !!currentCountMoment))
      .pipe(
        map(([currentCountMoment]) => ({
          ...currentCountMoment,
          status: this.countMomentService.getStatus(currentCountMoment)
        })),
      ).subscribe(({name, status, city_id, start_time, end_time}) => {
        this.status = status;
        this.name = name;
        this.cityId = city_id;
        this.startTime = start_time;
        this.endTime = end_time;

        if (status === 'BEFORE') {
          const start = moment(start_time);
          const now = moment();

          this.countdown = moment.duration(start.diff(now));
        }
      });

    this.hasCountedSubscription = this.countMomentService.hasCounted()
      .subscribe((hasCounted) => {
        this.hasCounted = hasCounted;
      });
  }

  ionViewWillLeave() {
    // not called due to Ionic bug:
    // https://github.com/ionic-team/ionic/issues/17992
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }

  async startCounting() {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }

    const currentCountMoment = await this.currentCountMoment$.pipe(take(1)).toPromise();
    const status = this.countMomentService.getStatus(currentCountMoment);

    if (status === 'AFTER') {
      this.router.navigate(['/done']);
    } else {
      this.router.navigate(['/count']);
    }
  }
}
