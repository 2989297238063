import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {LocationService} from '../services/location.service';
import {LoadLocations, LoadLocationsError, LoadLocationsSuccess, LocationActionTypes} from '../actions/location.actions';

@Injectable()
export class LocationEffects {
  constructor(
    private actions$: Actions,
    private locationService: LocationService,
  ) {
  }

  @Effect()
  fetch$ = this.actions$
    .pipe(
      ofType<LoadLocations>(LocationActionTypes.LoadLocations),
      mergeMap((action: LoadLocations) => this.locationService.getAll()
        .pipe(
          map((response: Location[]) => new LoadLocationsSuccess({locations: response})),
          catchError((response: any) => {
            console.error(response);
            return of(new LoadLocationsError());
          })
        ))
    );
}
