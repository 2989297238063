import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { LegendComponent } from '../../components/legend/legend.component';

/**
 * Generated class for the CountProgressBarComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'count-progress-bar',
  styleUrls: ['./count-progress-bar.scss'],
  templateUrl: 'count-progress-bar.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountProgressBarComponent {
  @Input('progress') progress;

  constructor(private popoverController: PopoverController) {
  }

  getMotivationalMessage(per) {
    if (per >= 75) {
      return {
        icon: 'trophy',
        message: 'De laatste loodjes.',
      };
    }

    if (per >= 50) {
      return {
        icon: 'heart',
        message: 'Halverwege.',
      };
    }

    if (per >= 25) {
      return {
        icon: 'thumbs-up-sharp',
        message: 'Goed bezig.',
      };
    }

    return {
      icon: 'thumbs-up-sharp',
      message: 'Veel succes.'
    };
  }

  async presentLegend() {
    const popover = await this.popoverController.create({
      component: LegendComponent,
      cssClass: 'legend-popover',
      translucent: true,
      backdropDismiss: true,
    });
    return await popover.present();
  }
}
