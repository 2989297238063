import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromStore from '../../reducers';
import {Router} from '@angular/router';
import {QuestionnaireService} from '../../services/questionnaire.service';
import {take} from 'rxjs/operators';
import {AlertController} from '@ionic/angular';
import * as Sentry from '@sentry/browser';
import {UserService} from '../../services/user.service';
import {environment} from '../../../environments/environment';
import {UpdateQuestionnaire} from '../../actions/questionnaire.actions';
import {Questionnaire} from '../../models/questionnaire.model';
import {selectQuestionnaire} from '../../reducers';

@Component({
  selector: 'app-questionnaire-meta-page',
  templateUrl: './questionnaire-meta-page.component.html',
  styleUrls: ['./questionnaire-meta-page.component.scss']
})
export class QuestionnaireMetaPageComponent implements OnInit {
  questionnaire$ = this.store.pipe(select(selectQuestionnaire));

  oneWay = false;
  sides: 'both' | 'single' = 'both';
  loading: boolean;

  constructor(
    private store: Store<fromStore.State>,
    private questionnaireService: QuestionnaireService,
    private router: Router,
    private userService: UserService,
    public alertController: AlertController,
  ) {
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    if (environment.debug) {
      this.userService.getUserDoc()
        .valueChanges()
        .pipe(take(1))
        .subscribe((user) => {
          Sentry.captureMessage(`${user ? user.email : 'unknown'} arrived at the questionnaire`);
        });
    }

    this.questionnaireService.get().pipe(take(1)).toPromise()
      .then((results) => {
        this.oneWay = results ? results.oneWay : false;
        this.sides = results ? results.sides : 'both';
      }, (e) => {
        console.error(e);
        Sentry.captureException(new Error(`error getting questionnaire: ${e}`));
      });
  }

  async onSubmit(form, data: Questionnaire) {
    this.loading = true;

    this.store.dispatch(new UpdateQuestionnaire({
      oneWay: data.oneWay,
      sides: data.sides
    }));

    const questionaire = await this.questionnaire$.pipe(take(1)).toPromise();

    this.questionnaireService.save({...questionaire}).then(() => {
      this.loading = false;
      this.router.navigate(['/done']);
    }, async () => {
      this.loading = false;

      const alert = await this.alertController.create({
        header: 'Oops',
        subHeader: 'Er liep iets mis',
        message: 'Probeer later nog eens',
        buttons: ['OK']
      });

      alert.present();
    });

  }

}
