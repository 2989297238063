import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromStore from '../../reducers';
// @ts-ignore
import {version as appVersion} from 'package.json';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.css']
})
export class AboutPageComponent implements OnInit {
  version = appVersion;

  constructor(
    private store: Store<fromStore.State>,
  ) {
  }

  ionViewDidEnter() {
  }

  ngOnInit() {
  }
}
