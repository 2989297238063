import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {CountMomentService} from '../services/count-moment.service';

import {
  LoadCountMoments,
  CountMomentActionTypes,
  LoadCountMomentsSuccess,
  LoadCountMomentsError,
  SetActiveCountMoment, LoadCountMomentSuccess, LoadCountMomentError
} from '../actions/count-moment.actions';
import {CountMoment} from '../models/count-moment.model';
import {UserService} from '../services/user.service';

@Injectable()
export class CountMomentEffects {
  constructor(
    private actions$: Actions,
    private countMomentService: CountMomentService,
    private userService: UserService,
  ) {
  }

  @Effect()
  fetch$ = this.actions$
    .pipe(
      ofType<LoadCountMoments>(CountMomentActionTypes.LoadCountMoment),
      mergeMap((action: LoadCountMoments) => this.countMomentService.getActive()
        .pipe(
          map((response: CountMoment) => new LoadCountMomentSuccess({countMoment: response})),
          catchError((response: any) => {
            console.error(response);
            return of(new LoadCountMomentError());
          })
        ))
    );

  @Effect()
  fetchAll$ = this.actions$
    .pipe(
      ofType<LoadCountMoments>(CountMomentActionTypes.LoadCountMoments),
      mergeMap((action: LoadCountMoments) => this.countMomentService.getAll()
        .pipe(
          map((response: CountMoment[]) => new LoadCountMomentsSuccess({countMoments: response})),
          catchError((response: any) => {
            console.error(response);
            return of(new LoadCountMomentsError());
          })
        ))
    );

  // on fail, wait 2 seconds retry again

  @Effect({dispatch: false})
  selectCountMoment$ = this.actions$
    .pipe(
      ofType<SetActiveCountMoment>(CountMomentActionTypes.SetActiveCountMoment),
      tap((action) => {
          if (action.payload.future) {
            this.userService.updateUser({selectedCountMoment: action.payload.id});
          }
        }
      ));
}
