import {Component, OnInit} from '@angular/core';
import {take} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.pipe((take(1))).toPromise()
        .then(({duration, autoCount}: any) => {
        if (duration) {
            localStorage.setItem('test-duration', duration);
        }

        if (autoCount) {
            localStorage.setItem('auto-count', autoCount);
        }
        });
    }
}
