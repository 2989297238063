export const environment = {
  adminEmail: 'admin@straatvinken.be',
  production: true,
  local: false,
  sentryEnvironment: 'production',
  gaTrackingCode: 'UA-85548337-5',
  apiUrl: 'https://api.straatvinken.be/api/',
  fakeCountMoment: false,
  fakeTestDuration: 6, // in minuten
  fakeTestCountdownDuration: 1, // in minuten
  debug: false,
  privacyPolicyURL: 'http://straatvinken.be/gdpr',
  google: {
    geoCode: {
      apiKey: 'AIzaSyDaCmV72NWUZk5C7IJRrpDCcolgIULUbko'
    }
  },
  firebase: {
    apiKey: 'AIzaSyDaCmV72NWUZk5C7IJRrpDCcolgIULUbko',
    authDomain: 'straatvinken-7e144.firebaseapp.com',
    databaseURL: 'https://straatvinken-7e144.firebaseio.com',
    projectId: 'straatvinken-7e144',
    storageBucket: 'straatvinken-7e144.appspot.com',
    messagingSenderId: '475610755132'
  },
  mapbox: {
    accessToken: 'pk.eyJ1Ijoic2ltb25zcGFjZWQiLCJhIjoiY2ttM2hmcDVjMjV5MTJxbHlvOHk4dW9xaCJ9.rnDdShMCw_HAfA918HfUHA'
  },
};
