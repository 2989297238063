import {Component, OnInit} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'app-splash-screen',
  animations: [
    trigger('Fade', [
      state('in', style({opacity: 0, display: 'none'})),
      transition(':enter', [
        style({opacity: 1}),
        animate('.6s 2s')
      ])
    ])
  ],
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {
  loaded: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

  imageLoaded() {
    this.loaded = true;
  }
}
