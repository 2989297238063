import {ErrorHandler, Injectable, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {CountDownComponent} from './components/count-down/count-down';
import {CountProgressBarComponent} from './components/count-progress-bar/count-progress-bar';
import {SpreadComponent} from './components/spread/spread';
import {TypeCountComponent} from './components/type-count/type-count';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducers} from './reducers';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {EffectsModule} from '@ngrx/effects';
import {LoginPageComponent} from './containers/login-page/login-page.component';
import {PipesModule} from './pipes/pipes.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {PractisePageComponent} from './containers/practise-page/practise-page.component';
import {StartPageComponent} from './containers/start-page/start-page.component';
import {CountPageComponent} from './containers/count-page/count-page.component';
import {AboutPageComponent} from './containers/about-page/about-page.component';
import {DonePageComponent} from './containers/done-page/done-page.component';
import {TipsPageComponent} from './containers/tips-page/tips-page.component';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {TypeEffects} from './effects/type.effects';
import {CountMomentEffects} from './effects/count-moment.effects';
import {LocationEffects} from './effects/location.effects';
import {WrapperComponent} from './containers/wrapper/wrapper.component';
import {QuestionnaireLocationPageComponent} from './containers/questionnaire-location-page/questionnaire-location-page.component';
import {AdminPageComponent} from './containers/admin-page/admin-page.component';
import {EmptyStateComponent} from './components/empty-state/empty-state.component';
import {CountMomentMetaComponent} from './components/count-moment-meta/count-moment-meta.component';
import {ColumnCardComponent} from './components/column-card/column-card.component';
import {WelcomeComponent} from './components/welcome/welcome.component';
import {AutoCompleteComponent} from './components/auto-complete/auto-complete.component';
import {ResultGraphComponent} from './components/result-graph/result-graph.component';
import {ResultGraphBarComponent} from './components/result-graph-bar/result-graph-bar.component';
import {ShareModule} from '@ngx-share/core';
import * as Sentry from '@sentry/browser';
import {registerLocaleData} from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import {Angulartics2Module} from 'angulartics2';
// @ts-ignore
import {version} from 'package.json';
import {QuestionnaireMapPageComponent} from './containers/questionnaire-map-page/questionnaire-map-page.component';
import {LegendComponent} from './components/legend/legend.component';
import {AccordionComponent} from './components/accordion/accordion.component';
import {SplashScreenComponent} from './components/splash-screen/splash-screen.component';
import {QuestionnaireMetaPageComponent} from './containers/questionnaire-meta-page/questionnaire-meta-page.component';
import {StepsComponent} from './components/steps/steps.component';
import {AgmCoreModule} from '@agm/core';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {MapService, NgxMapboxGLModule} from 'ngx-mapbox-gl';
import { MglLanguageDirective } from './directives/mgl-language.directive';

// Sentry.init({
//   dsn: 'https://630e20e5999241a2855a39e6c04dba48@sentry.io/301843',
//   debug: false,
//   environment: environment.sentryEnvironment,
//   release: version,
// });

registerLocaleData(localeNl, 'nl-BE');

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error) {
    Sentry.captureException(error.originalError || error);

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
      Sentry.captureMessage(`Reloading page because of Loading Chunk Error`);
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    CountDownComponent,
    CountProgressBarComponent,
    SpreadComponent,
    TypeCountComponent,
    LoginPageComponent,
    PractisePageComponent,
    StartPageComponent,
    CountPageComponent,
    AboutPageComponent,
    DonePageComponent,
    TipsPageComponent,
    WrapperComponent,
    QuestionnaireLocationPageComponent,
    AdminPageComponent,
    EmptyStateComponent,
    CountMomentMetaComponent,
    ColumnCardComponent,
    WelcomeComponent,
    AutoCompleteComponent,
    ResultGraphComponent,
    ResultGraphBarComponent,
    QuestionnaireMapPageComponent,
    LegendComponent,
    AccordionComponent,
    SplashScreenComponent,
    QuestionnaireMetaPageComponent,
    StepsComponent,
    MglLanguageDirective,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md',
    }),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([TypeEffects, CountMomentEffects, LocationEffects]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({maxAge: 25}),
    PipesModule,
    ShareModule,
    Angulartics2Module.forRoot(),
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.google.geoCode.apiKey,
      language: 'nl-BE',
      libraries: ['places']
    }),
    GooglePlaceModule,
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapbox.accessToken, // Optional, can also be set per map (accessToken input of mgl-map)
    })
  ],
  providers: [
    MapService,
    // {provide: ErrorHandler, useClass: SentryErrorHandler},
    {provide: LOCALE_ID, useValue: 'nl-BE'},
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
