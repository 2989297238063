import {Injectable} from '@angular/core';
import {CountMomentService} from './count-moment.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {
  constructor(private countMomentService: CountMomentService) {
  }

  get() {
    return this.countMomentService.ownCountMomentDoc.valueChanges()
      .pipe(map((countMoment: any) => {
        return countMoment ? countMoment.questionnaire : null;
      }));
  }

  save(results) {
    return this.countMomentService.ownCountMomentDoc
      .set({questionnaire: results}, {merge: true});
  }
}
