import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-result-graph-bar',
  templateUrl: './result-graph-bar.component.html',
  styleUrls: ['./result-graph-bar.component.scss']
})
export class ResultGraphBarComponent implements OnInit {
  @Input() percentage: number;
  @Input() color: string;


  constructor() {
  }

  ngOnInit() {
  }
}
