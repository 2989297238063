import {Pipe, PipeTransform} from '@angular/core';

/**
 * Generated class for the TimeToTextPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'timeToText',
})
export class TimeToTextPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: number, ...args) {
    value = value < 1 ? 1 : value;

    return value > 60 ? Math.ceil(value / 60) + ' minuten' : value + ' seconden';
  }
}
