import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromStore from '../../reducers';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

@Component({
  selector: 'app-practise-page',
  templateUrl: './practise-page.component.html',
  styleUrls: ['./practise-page.component.scss']
})
export class PractisePageComponent implements OnInit {
  activeTypeId: string;

  types$: Observable<any>;
  private counts = new BehaviorSubject({});

  constructor(private store: Store<fromStore.State>) {
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.types$ = combineLatest([this.store.select(fromStore.selectAllTypes), this.counts])
      .pipe(map(([types, counts]) => {
        return types.map((type) => {
          return {
            ...type,
            count: counts[type.slug] ? counts[type.slug] : 0,
          };
        });
      }));
  }

  addToCount(typeId: string) {
    this.activeTypeId = typeId;

    this.counts.pipe(take(1)).subscribe((counts) => {
      counts[typeId] = counts[typeId] ? counts[typeId] + 1 : 1;
      this.counts.next(counts);
    });
  }

  removeFromCount(typeId) {
    this.counts.pipe(take(1)).subscribe((counts) => {
      counts[typeId] = counts[typeId] && counts[typeId] > 0 ? counts[typeId] - 1 : 0;
      this.counts.next(counts);
    });
  }

  identify(i, item) {
    return i;
  }
}
