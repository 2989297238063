import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {filter, map, take} from 'rxjs/operators';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userDoc: AngularFirestoreDocument<any>;

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore) {
    this.afAuth.user
      .pipe(filter((user) => !!user))
      .subscribe((user) => {
        this.userDoc = afs.doc<any>(`users/${user.uid}`);
      });
  }

  updateUser(data) {
    return this.afAuth.user
      .pipe(filter((user) => !!user))
      .pipe(take(1))
      .toPromise()
      .then((user) => {
        const userDoc = this.afs.doc<any>(`users/${user.uid}`);
        return userDoc.set(data, {merge: true});
      });
  }

  getUserDoc() {
    return this.userDoc;
  }
}
