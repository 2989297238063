import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromStore from '../../reducers';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-tips-page',
  templateUrl: './tips-page.component.html',
  styleUrls: ['./tips-page.component.scss']
})
export class TipsPageComponent {
  activeCategory: 'all' | 'comfort' | 'location' | 'quality';
  private activatedRouteSubscription: Subscription;

  constructor(
    private store: Store<fromStore.State>,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ionViewWillEnter() {
    this.activatedRouteSubscription = this.activatedRoute.queryParams.subscribe(params => this.activeCategory = params['tip']);
  }

  ionViewWillLeave() {
    this.activatedRouteSubscription.unsubscribe();
  }

  goToExternal(url) {
    window.open(url, '_blank');
  }
}
