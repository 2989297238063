<ion-content>
  <div class="wrapper">
    <form #form="ngForm"
          (submit)="addNotification(form)">
      <ion-item>
        <ion-label>
          Telmoment
        </ion-label>

        <ion-select [name]="'countMomentId'"
                    [(ngModel)]="notification.countMomentId"
                    required>
          <ion-item *ngFor="let countMoment of (countMoments$ | async)">
            <ion-select-option [value]="countMoment.id">
              {{ countMoment.name }}
            </ion-select-option>
          </ion-item>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-label>Datum & tijd</ion-label>
        <ion-datetime
          [(ngModel)]="notification.date"
          [name]="'date'"
          required
          display-format="DD/MM/YYYY HH:mm">
        </ion-datetime>
      </ion-item>

      <ion-item>
        <ion-label>Titel</ion-label>
        <ion-input
          [(ngModel)]="notification.title"
          [name]="'title'"
          required>
        </ion-input>
      </ion-item>

      <ion-item>
        <ion-textarea
          [(ngModel)]="notification.message"
          [name]="'message'"
          placeholder="Bericht"
          required>
        </ion-textarea>
      </ion-item>

      <ion-button type="submit"
                  [disabled]="form.invalid"
                  expand="block">
        Add notification
      </ion-button>
    </form>

    <ion-list>
      <ion-card *ngFor="let notification of (notifications$ | async)">
        <ion-card-header>
          <ion-card-title>
            Telmoment: {{ notification.countMomentId | name:(countMoments$ | async) }}
          </ion-card-title>

          <ion-card-subtitle>
            Title: {{ notification.title }}
          </ion-card-subtitle>
        </ion-card-header>

        <ion-card-content>
          <p>
            Message: {{ notification.message }}
          </p>

          <p>
            Datum: {{ notification.date.toDate() | moment | date:'dd/MM/yyyy HH:mm' }}
          </p>

          <ion-button expand="block" (click)="removeNotification(notification.id)">
            delete notification
          </ion-button>
        </ion-card-content>
      </ion-card>
    </ion-list>
  </div>
</ion-content>