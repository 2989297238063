<div class="row" #container>
  <div class="type-count-button negative"
       (click)="animateAndRemove($event)">
    <div class="inner">
      <span>-</span>
    </div>
  </div>

  <div class="type-count-inner">
    <div class="type-count-label">
      <img width="50px" height="50px" [src]="'assets/images/types/' + slug + '.svg'" alt=""/>
      <p>{{name}}</p>
    </div>

    <div class="type-count-amount">
      <p>{{count || 0}}</p>
    </div>
  </div>

  <div class="type-count-button"
       (click)="animateAndAdd($event)">
    <div class="inner">
      <span>+</span>
    </div>
  </div>

  <div *ngFor="let ripple of ripples;trackBy: trackByFn; let i = index;"
       id="{{ i }}"
       [style.top]="ripple.top"
       [style.left]="ripple.left"
       [style.background-color]="color"
       [@ripple]='ripple.state'
       class="ripple">
  </div>
</div>