import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {combineLatest, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/auth';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn$: Observable<boolean>;

  constructor(private http: HttpClient, private afAuth: AngularFireAuth, private user: UserService) {
    this.loggedIn$ = this.afAuth.user.pipe(map((fbUser) => !!fbUser));
  }

  isAuthenticated(): Promise<boolean> {
    return this.loggedIn$.pipe(take(1)).toPromise();
  }

  isAuthenticatedObservable(): Observable<boolean> {
    return this.loggedIn$;
  }

  login(email: string): Promise<any> {
    return this.afAuth.signInAnonymously()
      .then(() => this.user.updateUser({email}));
  }

  logout() {
    return this.afAuth.signOut();
  }

  adminLogin(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }
}
