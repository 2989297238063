import {Pipe, PipeTransform} from '@angular/core';

/**
 * Generated class for the MinPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'min',
})
export class MinPipe implements PipeTransform {
  transform(value: number, min = 0) {
    return value < min ? min : Math.floor(value);
  }
}
