import {QuestionnaireActions, QuestionnaireActionTypes} from '../actions/questionnaire.actions';
import {Questionnaire} from '../models/questionnaire.model';

export const questionnaireFeatureKey = 'questionnaire';

// tslint:disable-next-line:no-empty-interface
export interface State extends Questionnaire {}

export const initialState: State  = {
  city: null,
  street: null,
  houseNr: null,
  oneWay: false,
  sides: 'both',
  coordinates: {
    lat: null,
    lng: null,
  },
};

export function reducer(
  state = initialState,
  action: QuestionnaireActions
): State {
  switch (action.type) {
    case QuestionnaireActionTypes.UpdateQuestionnaire: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}

export const selectAll = (state: State) => state;
