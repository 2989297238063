import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the MaxPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'max',
})
export class MaxPipe implements PipeTransform {
  transform(value: number, ...args) {
    return value > 100 ? 100 : Math.floor(value);
  }
}
