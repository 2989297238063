import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'name'
})
export class NamePipe implements PipeTransform {
  transform(value: any, collection: any, args?: any): any {
    return collection.filter((item) => item.id === value)[0].name;
  }
}
