import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {
  @Input() steps: number;
  @Input() active: number;

  stepsAmount: Array<any>;

  constructor() { }

  ngOnInit(): void {
    this.stepsAmount = new Array(this.steps);
  }

}
