import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {Location} from '../models/location.model';
import {LocationActions, LocationActionTypes} from '../actions/location.actions';

export interface State extends EntityState<Location> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Location> = createEntityAdapter<Location>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(state = initialState, action: LocationActions): State {
  switch (action.type) {
    case LocationActionTypes.LoadLocationsSuccess: {
      return adapter.addAll(action.payload.locations, state);
    }

    default: {
      return state;
    }
  }
}

export const {
  selectAll,
} = adapter.getSelectors();
