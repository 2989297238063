<ion-content>
  <div class="wrapper">
    <p class="ion-no-margin">Indien de locatie op de kaart niet overeenkomt met jouw meetlocatie, kan je hier de kaart
      verschuiven.</p>



    <div class="steps">
      <app-steps [steps]="3" [active]="1"></app-steps>
      <ion-button expand="block"
                  [routerLink]="'/questionnaire-meta'"
                  [queryParams]="{backButton: true}">
        Volgende
      </ion-button>
    </div>
  </div>
</ion-content>