<ion-content>
  <h2 class="ion-no-margin ion-margin-bottom">Over Straatvinken</h2>
  <p>
    Straatvinken is een telmoment om na te gaan hoe gezond het verkeer is in onze straten. Tijdens het telmoment noteer
    je samen met vele andere burgers gedurende één uur hoeveel voetgangers, fietsers en voertuigen er door je straat
    passeren. Wetenschappers onderwerpen de resultaten vervolgens aan een analyse. Straatvinken helpt mee om fijnmazige
    mobiliteitsdata te verzamelen, zet de modal shift hoog op de agenda en berekent hoe leefbaar jouw straat is.
  </p>

  <h2>Deze tel-tool</h2>
  <p>
    Met deze tel-tool kan je het verkeer tellen in je straat op een gezamenlijk telmoment.
    Je telresultaten worden samengevoegd met die van andere Straatvinken.
  </p>

  <p>Deze tel-tool kwam tot stand in een samenwerking tussen
    <a href="https://ringland.be" target="_blank" rel="noopener noreferrer">Ringland</a> en
    <a href="https://spaced.be" target="_blank" rel="noopener noreferrer" class="link-spaced">Spaced</a>.
  </p>

  <p>Versie: {{ version }}</p>
</ion-content>