import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';

import {CountMoment} from '../models/count-moment.model';
import {Router} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {selectCurrentCountMomentId} from '../reducers';
import * as fromStore from '../reducers';
import {filter, map, take} from 'rxjs/operators';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import {GeoService} from './geo.service';
import * as Sentry from '@sentry/browser';

@Injectable({
  providedIn: 'root'
})
export class CountMomentService {
  public ownCountMomentDoc: AngularFirestoreDocument<any>;
  private userId: any;
  private ownCountMomentCountCollection: AngularFirestoreCollection<any>;

  constructor(private store: Store<fromStore.State>,
              private afs: AngularFirestore,
              private afAuth: AngularFireAuth,
              private http: HttpClient,
              private router: Router,
              private geo: GeoService,
  ) {
    combineLatest(
      this.store.pipe(select(selectCurrentCountMomentId)),
      this.afAuth.user,
    ).pipe(filter(([countMomentId, user]) => !!countMomentId && !!user))
      .subscribe(([countMomentId, user]) => {
        this.userId = user.uid;

        this.ownCountMomentDoc = this.afs.collection('count-moments')
          .doc(`count-moment-${countMomentId}`)
          .collection(`users`)
          .doc(`${user.uid}`);

        this.ownCountMomentCountCollection = this.afs.collection(`/count-moments/count-moment-${countMomentId}/users/${user.uid}/counts`);
      });
  }

  getAll() {
    return this.http.get(
      `${environment.apiUrl}count-moment`
    ).pipe(map((countMoments: any[]) => {
      return countMoments.map((countMoment) => {
        return {
          ...countMoment,
        };
      });
    }));
  }

  getActive() {
    return this.http.get(
      `${environment.apiUrl}active-count-moment`
    ).pipe(map((countMoment: CountMoment) => {
      return {...countMoment};
    }));
  }

  getStatus(countMoment: CountMoment) {
    if (!countMoment) {
      return;
    }

    const current = new Date();

    const startTime = moment(countMoment.start_time);
    const endTime = moment(countMoment.end_time);
    const currentTime = moment(current);

    if (currentTime.isBefore(startTime)) {
      return 'BEFORE';
    }

    if (currentTime.isAfter(startTime) && currentTime.isBefore(endTime)) {
      return 'DURING';
    }

    return 'AFTER';
  }


  getProgress(countMoment: CountMoment) {
    if (!countMoment) {
      return;
    }

    const startTime = moment(countMoment.start_time);
    const endTime = moment(countMoment.end_time);

    const currentTime = moment(new Date());

    const current = currentTime.diff(startTime);
    const total = endTime.diff(startTime);
    const timeLeft = endTime.diff(currentTime, 'seconds');

    return {
      per: current / total * 100,
      timeLeft: timeLeft
    };
  }

  redirectBasedOnStatus(status) {
    switch (status) {
      case 'BEFORE':
        this.router.navigate(['/start']);
        break;
      case 'DURING':
        this.router.navigate(['/start']);
        break;
      case 'AFTER':
        this.router.navigate(['/done']);
        break;
    }
  }

  get(): Observable<any> {
    return this.ownCountMomentDoc.valueChanges();
  }

  increment(typeSlug): Promise<any> {
    return this.geo.current$.pipe(take(1)).toPromise()
      .then((geo) => {
        return this.ownCountMomentDoc.collection('counts').add({
          ...geo,
          typeSlug,
          action: 'increment',
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }, (e) => {
        console.error(e);

        Sentry.captureException(new Error(`error getting geo data: ${e}`));

        return this.ownCountMomentDoc.collection('counts').add({
          typeSlug,
          action: 'increment',
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
      });
  }

  async decrement(typeSlug): Promise<any> {
    return this.geo.current$.pipe(take(1)).toPromise()
      .then((geo) => {
        return this.ownCountMomentDoc.collection('counts').add({
          ...geo,
          typeSlug,
          action: 'decrement',
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }, (e) => {
        console.error(e);

        Sentry.captureException(new Error(`error getting geo data: ${e}`));

        return this.ownCountMomentDoc.collection('counts').add({
          typeSlug,
          action: 'decrement',
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
      });
  }

  startCounting() {
    this.ownCountMomentDoc
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((countMoment) => {
        if (!countMoment || !countMoment.startedCounting) {
          this.ownCountMomentDoc.set({startedCounting: firebase.firestore.FieldValue.serverTimestamp()}, {merge: true});
        }
      });
  }

  endCounting() {
    this.ownCountMomentDoc
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((countMoment) => {
        if (!countMoment || !countMoment.endedCounting) {
          this.ownCountMomentDoc.set({endedCounting: firebase.firestore.FieldValue.serverTimestamp()}, {merge: true});
        }
      });
  }

  hasCounted(): Observable<boolean> {
    return this.ownCountMomentDoc
      .snapshotChanges()
      .pipe(
        map((doc) => {
          return doc.payload.exists;
        })
      );
  }

  calculate() {
    const types = {};

    return this.ownCountMomentCountCollection
      .valueChanges()
      .pipe(take(1))
      .toPromise()
      .then((counts = []) => {
        counts.forEach((count) => {
          if (typeof types[count.typeSlug] === 'undefined') {
            types[count.typeSlug] = 0;
          }

          types[count.typeSlug] = count.action === 'increment' ? types[count.typeSlug] + 1 : types[count.typeSlug] - 1;
        });

        return types;
      });
  }
}
