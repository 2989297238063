import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromStore from '../../reducers';
import {Router} from '@angular/router';
import {QuestionnaireService} from '../../services/questionnaire.service';
import {distinctUntilChanged, take, takeUntil} from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
import {UserService} from '../../services/user.service';
import {environment} from '../../../environments/environment';
import {FormBuilder, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {LocationService} from '../../services/location.service';
import {Options} from 'ngx-google-places-autocomplete/objects/options/options';
import {IonSearchbar} from '@ionic/angular';
import {UpdateQuestionnaire} from '../../actions/questionnaire.actions';
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import {selectQuestionnaire} from '../../reducers';
import * as firebase from 'firebase';
import {CountMomentService} from '../../services/count-moment.service';

@Component({
  selector: 'app-questionnaire-location-page',
  templateUrl: './questionnaire-location-page.component.html',
  styleUrls: ['./questionnaire-location-page.component.scss']
})
export class QuestionnaireLocationPageComponent implements OnInit, OnDestroy {
  @ViewChild('searchbar', {read: IonSearchbar}) searchbar: IonSearchbar;

  questionnaire$ = this.store.pipe(select(selectQuestionnaire));

  locationForm = this.formBuilder.group({
    search: [{value: '', disabled: false}, Validators.required],
  });

  loading: boolean;

  autocompleteItems = [];
  hasSelectedAddress = false;
  isSearching = false;

  private destroyed$ = new Subject();
  private googleAutocompleteService = new google.maps.places.AutocompleteService();

  private geocodeOptions: Partial<Options> = {
    types: ['address'],
    componentRestrictions: {country: 'BE'}
  };

  private selectedAddress: google.maps.places.AutocompletePrediction;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<fromStore.State>,
    private questionnaireService: QuestionnaireService,
    private router: Router,
    private userService: UserService,
    private locationService: LocationService,
    private countMomentService: CountMomentService,
    private ngZone: NgZone,
  ) {
    this.locationForm.get('search')
      .valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged(),
      )
      .subscribe((input) => {
        this.hasSelectedAddress = false;
        this.selectedAddress = null;
        this.isSearching = true;

        if (!input) {
          this.isSearching = false;

          return;
        }

        this.googleAutocompleteService.getPlacePredictions(
          {input, ...this.geocodeOptions},
          (predictions, status) => {
            this.autocompleteItems = [];

            this.ngZone.run(() => {
              predictions?.forEach((prediction) => {
                this.autocompleteItems.push(prediction);
              });
            });
          });
      });

    this.countMomentService.endCounting();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  ionViewWillEnter() {
    if (environment.debug) {
      this.userService.getUserDoc()
        .valueChanges()
        .pipe(take(1))
        .subscribe((user) => {
          Sentry.captureMessage(`${user ? user.email : 'unknown'} arrived at the questionnaire`);
        });
    }
  }

  mapDrag($event: any) {
  }

  async markerDrag($event: mapboxgl.Marker) {
    const latLng = $event.getLngLat();

    await this.store.dispatch(new UpdateQuestionnaire({
      coordinates: {
        lat: latLng.lat,
        lng: latLng.lng,
      },
    }));
  }

  async selectSearchResultItem(item: AutocompletePrediction) {
    this.locationForm.get('search').setValue(item.description);

    this.selectedAddress = item;

    this.loading = true;

    const address = await this.locationService.getAddress(this.selectedAddress.place_id).pipe(take(1)).toPromise();

    const houseNr = address[0].address_components.find(component => component.types.includes('street_number'));
    const street = address[0].address_components.find(component => component.types.includes('route'));
    const city = address[0].address_components.find(component => component.types.includes('locality'));

    const coordinates = {
      lat: address[0].geometry.location.lat(),
      lng: address[0].geometry.location.lng()
    };

    await this.store.dispatch(new UpdateQuestionnaire({
      city: city.long_name,
      street: street.long_name,
      houseNr: houseNr?.long_name || null,
      placeId: item.place_id,
      coordinates,
    }));

    this.loading = false;
    this.hasSelectedAddress = true;
  }

  async onSubmit() {
    return this.router.navigate(['/questionnaire-meta']);
  }
}
