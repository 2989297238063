import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {select, Store} from '@ngrx/store';
import {dataLoaded, selectCurrentCountMoment} from '../reducers';
import {filter, map, take, withLatestFrom} from 'rxjs/operators';
import * as fromStore from '../reducers';
import {CountMomentService} from '../services/count-moment.service';

@Injectable({
  providedIn: 'root'
})
export class NotLoggedInGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private store: Store<fromStore.State>,
    private router: Router,
    private countMomentService: CountMomentService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    return this.auth.isAuthenticated()
      .then((loggedIn: boolean) => {
        if (!loggedIn) {
          return true;
        }

        return this.store.pipe(select(dataLoaded))
          .pipe(filter((loaded) => loaded))
          .pipe(withLatestFrom(this.store.pipe(select(selectCurrentCountMoment))))
          .pipe(take(1))
          .pipe(map(([loaded, countMoment]) => {
            if (!countMoment) {
              this.router.navigate(['/start']);
              return false;
            }

            const status = this.countMomentService.getStatus(countMoment);
            this.countMomentService.redirectBasedOnStatus(status);

            return false;
          }))
          .toPromise();
      });
  }
}
