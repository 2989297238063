<ion-card color="dark-grey">
  <ion-card-content>
    <div class="number h1">{{countdown?.asDays() | min}}</div>
    <div>{{(countdown?.get('days') | min) === 1 ? 'dag' : 'dagen' }}</div>
  </ion-card-content>
</ion-card>
<ion-card color="dark-grey">
  <ion-card-content>
    <div class="number h1">{{countdown?.get('hours') | min}}</div>
    <div>uur</div>
  </ion-card-content>
</ion-card>
<ion-card color="dark-grey">
  <ion-card-content>
    <div class="number h1">{{countdown?.get('minutes') | min}}</div>
    <div>min</div>
  </ion-card-content>
</ion-card>
<ion-card color="dark-grey">
  <ion-card-content>
    <div class="number h1">{{countdown?.get('seconds') | min}}</div>
    <div>sec</div>
  </ion-card-content>
</ion-card>