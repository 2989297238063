import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromStore from '../../reducers';
import {AuthService} from '../../services/auth.service';
import {AlertController, LoadingController} from '@ionic/angular';
import {Router} from '@angular/router';
import {CountMomentService} from '../../services/count-moment.service';
import {environment} from '../../../environments/environment';
import * as Sentry from '@sentry/browser';
import {SetActiveCountMoment} from '../../actions/count-moment.actions';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  public email: string;
  public privacyPolicyURL: string;
  private loading: any;

  constructor(
    private store: Store<fromStore.State>,
    private auth: AuthService,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private router: Router,
    private countMomentService: CountMomentService,
  ) {
    this.privacyPolicyURL = environment.privacyPolicyURL;
  }

  ngOnInit() {
  }

  async login({email}) {
    if (email !== environment.adminEmail) {
      this.anonLogin(email);
    } else {
      this.adminLogin(email);
    }
  }

  async anonLogin(email) {
    await this.showLoading();

    this.auth.login(email).then(() => {
      return this.redirect();
    }, (e) => {
      this.auth.logout();

      console.error('error logging in', e);
      Sentry.captureException(new Error(`error logging in: ${e}`));

      this.hideLoading();

      this.alertCtrl.create({
        header: 'Er is iets misgelopen. Probeer het nog eens.',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
          }
        ]
      }).then((prompt) => {
        prompt.present();
      });
    });
  }

  redirect() {
    this.countMomentService.getActive().pipe(take(1)).subscribe(countMoment => {
      this.store.dispatch(new SetActiveCountMoment({id: countMoment.id, future: true}));
      this.countMomentService.redirectBasedOnStatus(this.countMomentService.getStatus(countMoment));
      this.hideLoading();
    });
  }

  async adminLogin(email) {
    const prompt = await this.alertCtrl.create({
      header: 'voor wachtwoord in',
      inputs: [
        {
          name: 'password',
          type: 'text',
          placeholder: 'wachwoord'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Ok',
          handler: ({password}) => {
            this.auth.adminLogin(email, password).then(() => this.redirect());
          }
        }
      ]
    });

    await prompt.present();
  }

  private async showLoading() {
    this.loading = await this.loadingCtrl.create({
      message: 'Even geduld...'
    });

    this.loading.present();
  }

  private hideLoading() {
    if (this.loading) {
      this.loading.dismiss();
    }
  }
}

