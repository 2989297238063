import { Component, OnInit } from '@angular/core';
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit {

  constructor(private popoverController: PopoverController) { }

  ngOnInit() {
  }

    dismissPopover() {
      this.popoverController.dismiss();
    }
}
