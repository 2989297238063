<ion-app>
  <ion-router-outlet animated="false"></ion-router-outlet>
  <section style="display: none;">
    <img hidden *ngFor="let img of preloadImages;" [src]="img" alt=""/>

    <ion-spinner></ion-spinner>
    <ion-row>
      <ion-col></ion-col>
    </ion-row>
    <ion-checkbox></ion-checkbox>
    <ion-radio-group>
      <ion-radio></ion-radio>
    </ion-radio-group>
    <ion-card>
      <ion-card-header></ion-card-header>
      <ion-card-content></ion-card-content>
    </ion-card>
    <ion-list>
      <ion-list-header>
        <ion-item></ion-item>
      </ion-list-header>
    </ion-list>
    <ion-label></ion-label>
  </section>
</ion-app>
