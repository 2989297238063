import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GeoService {
  current$ = new BehaviorSubject({lat: 0, lng: 0});

  private geoSubcription: Subscription;

  async start() {
    // switch to native geo location
  }

  constructor(public http: HttpClient,
  ) {
  }
}
