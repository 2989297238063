<ion-content>
  <form #form="ngForm"
        (ngSubmit)="onSubmit(form, {oneWay:oneWay, sides: sides})">
    <div class="wrapper">
      <div>
        <h2 class="ion-no-margin ion-margin-bottom">Goed geteld!</h2>
        <p>Beantwoord nog snel even deze vragen.</p>

        <div class="label">Eénrichtingsstraat?</div>

        <ion-item class="ion-no-padding">
          <ion-label class="ion-text-wrap">Ja, ik telde in een éénrichtingsstraat</ion-label>
          <ion-checkbox name="oneWay" [(ngModel)]="oneWay"></ion-checkbox>
        </ion-item>

        <div class="label">In welke richting(en) heb je geteld?</div>

        <ion-radio-group [(ngModel)]="sides" name="sides">
          <ion-item class="ion-no-padding">
            <ion-label class="ion-text-wrap">Beide richtingen</ion-label>
            <ion-radio value="both" name="both"></ion-radio>
          </ion-item>

          <ion-item class="ion-no-padding">
            <ion-label class="ion-text-wrap">Eén rijrichting</ion-label>
            <ion-radio value="single" name="single"></ion-radio>
          </ion-item>
        </ion-radio-group>
      </div>

      <div class="steps">
        <app-steps [steps]="2" [active]="1"></app-steps>
        <ion-button id="submitButton"
                    expand="block"
                    [disabled]="!form.form.valid || loading"
                    [type]="'submit'">
          Opslaan

          <ion-spinner *ngIf="loading"></ion-spinner>
        </ion-button>
      </div>
    </div>
  </form>
</ion-content>
