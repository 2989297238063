<ion-item *ngFor="let result of results" lines="none">
  <div class="icon">
    <img width="60px" height="30px" [src]="'assets/images/types/' + result.slug + '.svg'">
  </div>

  <app-result-graph-bar [percentage]="result.countPercentage"
                        [color]="result.color"></app-result-graph-bar>

  <div class="number" slot="end">
    {{result.count}}
  </div>
</ion-item>