import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CountMoment } from '../models/count-moment.model';
import { CountMomentActions, CountMomentActionTypes } from '../actions/count-moment.actions';

export interface State extends EntityState<CountMoment> {
  selectedCountMomentId: number | null;
}

export const adapter: EntityAdapter<CountMoment> = createEntityAdapter<CountMoment>();

export const initialState: State = adapter.getInitialState({
  selectedCountMomentId: null,
});

export function reducer(
  state = initialState,
  action: CountMomentActions
): State {
  switch (action.type) {
    case CountMomentActionTypes.SetActiveCountMoment: {
      return {
        ...state,
        selectedCountMomentId: action.payload.id,
      };
    }

    case CountMomentActionTypes.AddCountMoment: {
      return adapter.addOne(action.payload.countMoment, state);
    }

    case CountMomentActionTypes.UpsertCountMoment: {
      return adapter.upsertOne(action.payload.countMoment, state);
    }

    case CountMomentActionTypes.AddCountMoments: {
      return adapter.addMany(action.payload.countMoments, state);
    }

    case CountMomentActionTypes.UpsertCountMoments: {
      return adapter.upsertMany(action.payload.countMoments, state);
    }

    case CountMomentActionTypes.UpdateCountMoment: {
      return adapter.updateOne(action.payload.countMoment, state);
    }

    case CountMomentActionTypes.UpdateCountMoments: {
      return adapter.updateMany(action.payload.countMoments, state);
    }

    case CountMomentActionTypes.DeleteCountMoment: {
      return adapter.removeOne(action.payload.id, state);
    }

    case CountMomentActionTypes.DeleteCountMoments: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case CountMomentActionTypes.LoadCountMomentsSuccess: {
      return adapter.upsertMany(action.payload.countMoments, state);
    }

    case CountMomentActionTypes.ClearCountMoments: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const getSelectedCountMomentId = (state: State) => state.selectedCountMomentId;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
