import {Injectable, NgZone} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AgmGeocoder, MapsAPILoader} from '@agm/core';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(
    private http: HttpClient,
    private agmGeoCoder: AgmGeocoder,
    private mapsAPILoader: MapsAPILoader,
  ) {
  }

  getAll() {
    return this.http.get(
      `${environment.apiUrl}city`
    );
  }

  getAddressFromPostalCode(postalCode: string) {
    if (!postalCode) {
      return;
    }

    return this.agmGeoCoder.geocode({componentRestrictions: {postalCode, country: 'BE'}});
  }

  getAddress(placeId) {
    return this.agmGeoCoder.geocode({
      placeId
    });
  }
}
