<ion-menu menuId="first"
          content-id="main"
          side="start"
          type="overlay"
          *ngIf="(isAuthenticated$ | async)">
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button>
          <ion-icon color="dark" name="close"></ion-icon>
        </ion-menu-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content color="lightest">
    <ion-list>
      <ion-item (click)="closeMenu()"
                routerLink="/start"
                routerLinkActive="link-active"
                detail="false">
        <ion-label>Telmoment</ion-label>
      </ion-item>
      <ion-item (click)="closeMenu()"
                routerLink="/tips"
                routerLinkActive="link-active"
                [queryParams]="{tip: 'all'}"
                detail="false">
        <ion-label>Tips</ion-label>
      </ion-item>
      <ion-item (click)="closeMenu()"
                routerLink="/practise"
                routerLinkActive="link-active"
                detail="false">
        <ion-label>Oefenen</ion-label>
      </ion-item>
      <ion-item (click)="closeMenu()"
                href="https://straatvinken.be/vragen/"
                target="_blank"
                rel="noopener noreferrer"
                detail="false">
        <ion-label>Meest gestelde vragen</ion-label>
        <ion-icon name="arrow-forward" slot="end"></ion-icon>
      </ion-item>
      <ion-item (click)="closeMenu()"
                routerLink="/about"
                routerLinkActive="link-active"
                detail="false">
        <ion-label>Over Straatvinken</ion-label>
      </ion-item>
      <ion-item (click)="closeMenu()"
                *ngIf="isAdmin$ | async"
                routerLink="/admin"
                routerLinkActive="link-active"
                detail="false">
        <ion-label>Admin</ion-label>
      </ion-item>

      <ion-item (click)="logout()"
                *ngIf="isDevelopment"
                routerLinkActive="link-active"
                detail="false">
        <ion-label>Uitloggen</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</ion-menu>

<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start" class="buttons-start">
      <ion-button *ngIf="backButton"
                  (click)="back()"
                  [color]="'dark'">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
      <ion-menu-button color="dark" *ngIf="!backButton && (isAuthenticated$ | async)"></ion-menu-button>
    </ion-buttons>

    <ion-title>
      <div class="logo-text">
          <img class="logo"
               [src]="'assets/images/logo-header-dark.svg'"
               [srcset]="'assets/images/logo-header-dark.svg 2x'"
               alt="Straatvinken"/>
        </div>
    </ion-title>

    <ion-buttons slot="end">
      <ion-button *ngIf="legendAvailable != null"
                  (click)="presentLegend()"
                  [color]="'primary'">
        <ion-icon name="information-circle" class="legend-icon" size="large"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content id="main">
  <ion-router-outlet animated="true" class="page-content" id="pageContent"></ion-router-outlet>
</ion-content>
