<div class="wrapper ion-padding">
  <form id="loginForm" class="form-wrapper" (ngSubmit)="login({email: email})" #form>
    <div class="content">
      <div class="logo-wrapper">
        <img width="160px"
             class="logo"
             [src]="'assets/images/logo-header-dark.svg'"
             [srcset]="'assets/images/logo-header-dark.svg 2x'"
             alt="Straatvinken"/>
      </div>

      <img [src]="'assets/images/illustrations/ready.svg'" class="slide-image" alt=""/>

      <div class="no-shrink">
        <h2 id="login-title" class="ion-no-margin ion-margin-bottom">Registreren</h2>
        <p>Om je op de hoogte te houden van de resultaten van Straatvinken en voor kwaliteitscontrole hebben we je
          e-mailadres nodig. Meer info over de verwerking van je gegevens vind je
          <a [href]="privacyPolicyURL" target="_blank" rel="noopener noreferrer">hier</a>.</p>

        <ion-item class="ion-no-padding">
          <ion-label position="stacked">E-mailadres</ion-label>
          <ion-input required
                     placeholder="straatvink@gmail.com"
                     type="email"
                     name="email"
                     inputmode="email"
                     [(ngModel)]="email"></ion-input>
        </ion-item>
      </div>

    </div>

    <div class="steps">
      <app-steps [steps]="2" [active]="1"></app-steps>
      <ion-button id="loginButton"
                  type="submit"
                  [disabled]="!form.checkValidity()">
        Registreer
      </ion-button>
    </div>
  </form>
</div>