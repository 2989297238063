<ion-content>
  <ng-container *ngIf="status === 'BEFORE'">
    <img [src]="'/assets/images/illustrations/before.svg'" class="illustration" alt="">

    <h2>Het volgende telmoment start binnen...</h2>
    <app-count-down [countdown]="countdown"></app-count-down>
    <div class="start-date">
      <h3 class="ion-no-margin">
        {{startTime | moment | date:'d/MM/yyyy' | lowercase }}
      </h3>
      <h3 class="ion-no-margin">
        {{startTime | moment | date: 'HHumm'}} - {{endTime | moment | date: 'HHumm'}}
      </h3>
    </div>
  </ng-container>

  <ng-container *ngIf="status === 'DURING'">
    <img [src]="'/assets/images/illustrations/ready.svg'" class="illustration" alt="">

    <h2>Het telmoment is bezig</h2>
    <ion-button color="dark-grey"
                expand="block"
                id="startButton"
                size="large"
                [disabled]="status === 'BEFORE'"
                (click)="startCounting()">
      start
    </ion-button>
    <div class="start-date">
      <h3 class="ion-no-margin">
        {{startTime | moment | date:'d/MM/yyyy' | lowercase }}
      </h3>
      <h3 class="ion-no-margin">
        {{startTime | moment | date: 'HHumm'}} - {{endTime | moment | date: 'HHumm'}}
      </h3>
    </div>
  </ng-container>

  <ng-container *ngIf="status === 'AFTER'">
    <img [src]="'/assets/images/illustrations/after.svg'" class="illustration" alt="">

    <h2>Het telmoment is afgelopen</h2>
    <p class="after">
      We zien je graag volgend jaar terug voor een nieuwe telling.
      <span *ngIf="hasCounted">Hieronder kan je je resultaten van de voorbije editie herbekijken.</span>
      <span *ngIf="!hasCounted">Hieronder kan je je alvast voorbereiden op de volgende editie.</span>
    </p>

    <div *ngIf="hasCounted">
      <span></span>

      <ion-button [routerLink]="'/done'"
                  [queryParams]="{backButton: true}"
                  expand="block">
        Mijn resultaten
      </ion-button>
    </div>
  </ng-container>

  <ng-container *ngIf="!hasCounted">
    <ion-button [routerLink]="'/tips'"
                [queryParams]="{tip: 'all', backButton: true}"
                expand="block">
      Tips
    </ion-button>
    <ion-button [routerLink]="'/practise'"
                [queryParams]="{backButton: true}"
                expand="block">
      Oefenen
    </ion-button>
  </ng-container>
</ion-content>