import {Component, NgZone, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromStore from '../../reducers';
import {selectAllTypes, selectCurrentCountMoment} from '../../reducers';
import {combineLatest, interval, Subscription} from 'rxjs';
import {CountMomentService} from '../../services/count-moment.service';
import {Router} from '@angular/router';
import {map, startWith, take} from 'rxjs/operators';
import {Type} from '../../models/type.model';
import {TypeService} from '../../services/type.service';
import {GeoService} from '../../services/geo.service';
import {LoadingController} from '@ionic/angular';
import * as Sentry from '@sentry/browser';
import {environment} from '../../../environments/environment';
import {UserService} from '../../services/user.service';
import {AngularFireAuth} from '@angular/fire/auth';

@Component({
  selector: 'app-count-page',
  templateUrl: './count-page.component.html',
  styleUrls: ['./count-page.component.scss']
})
export class CountPageComponent implements OnInit {
  currentCountMoment$ = this.store.pipe(select(selectCurrentCountMoment));

  activeTypeSlug: string;
  status: string;
  progress: any;
  types: Type[];

  private types$ = this.store.pipe(select(selectAllTypes));
  private statusSubscription: Subscription;
  private typesSubscription: Subscription;

  private count: any = {};

  constructor(
    private store: Store<fromStore.State>,
    private countMomentService: CountMomentService,
    private typeService: TypeService,
    private router: Router,
    private geo: GeoService,
    private ngZone: NgZone,
    private loadingCtrl: LoadingController,
    private afAuth: AngularFireAuth,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
  }

  addToCount(typeSlug: string) {
    this.activeTypeSlug = typeSlug;

    this.count[typeSlug] = this.count[typeSlug] ? this.count[typeSlug] + 1 : 1;

    this.ngZone.runOutsideAngular(() => {
      this.countMomentService.increment(typeSlug);
    });
  }

  removeFromCount(typeSlug: string) {
    if (!this.count[typeSlug]) {
      return;
    }

    if (this.count[typeSlug] <= 0) {
      return;
    }

    this.count[typeSlug] = this.count[typeSlug] - 1;

    this.ngZone.runOutsideAngular(() => {
      this.countMomentService.decrement(typeSlug);
    });
  }

  async ionViewWillEnter() {
    this.geo.start();

    const loading = await this.loadingCtrl.create({
      message: 'Aan het laden...',
    });

    loading.present();

    this.countMomentService.calculate()
      .then((initialCount) => {
        this.count = {
          ...initialCount
        };

        loading.dismiss();
      }, (e) => {
        console.error(e);

        Sentry.captureException(new Error(`error calculating: ${e}`));
      });

    this.countMomentService.startCounting();

    this.typesSubscription = this.types$.subscribe((types: any) => {
      this.types = types;
    });

    if (environment.debug) {
      this.userService.getUserDoc()
        .valueChanges()
        .pipe(take(1))
        .subscribe((user) => {
          Sentry.captureMessage(`Waiting for ${user ? user.email : 'unknown'} to finish counting`);
        });
    }

    this.statusSubscription = combineLatest([
      this.currentCountMoment$,
      interval(500).pipe(startWith(null)),
    ]).pipe(
      map(([currentCountMoment]) => ({
        status: this.countMomentService.getStatus(currentCountMoment),
        progress: this.countMomentService.getProgress(currentCountMoment),
      })),
    ).subscribe(({status, progress}) => {
      this.status = status;
      this.progress = progress;

      if (status === 'AFTER') {
        if (environment.debug) {
          this.userService.getUserDoc()
            .valueChanges()
            .pipe(take(1))
            .subscribe((user) => {
              Sentry.captureMessage(`Unsubscribing observables and redirecting$ ${user ? user.email : 'unknown'} to questionnaire`);
            });
        }

        if (this.statusSubscription) {
          this.statusSubscription.unsubscribe();
        }

        if (this.typesSubscription) {
          this.typesSubscription.unsubscribe();
        }

        this.router.navigate(['/questionnaire-location']);
      }

      if (environment.fakeCountMoment && this.types) {
        const autoCount: string = localStorage.getItem('auto-count');

        if (!autoCount) {
          return;
        }

        const type = this.types[this.randomNumber(0, this.types.length - 1)];

        if (this.randomNumber(0, 3)) {
          this.addToCount(type.slug);
        } else {
          this.removeFromCount(type.slug);
        }
      }
    });
  }

  randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  ionViewWillLeave() {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }

    if (this.typesSubscription) {
      this.typesSubscription.unsubscribe();
    }
  }

  identify(index, item) {
    return item.id;
  }
}
