import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LoginPageComponent} from './containers/login-page/login-page.component';
import {AboutPageComponent} from './containers/about-page/about-page.component';
import {CountPageComponent} from './containers/count-page/count-page.component';
import {DonePageComponent} from './containers/done-page/done-page.component';
import {PractisePageComponent} from './containers/practise-page/practise-page.component';
import {StartPageComponent} from './containers/start-page/start-page.component';
import {TipsPageComponent} from './containers/tips-page/tips-page.component';
import {LoggedInGuard} from './guards/logged-in.guard';
import {NotLoggedInGuard} from './guards/not-logged-in.guard';
import {WrapperComponent} from './containers/wrapper/wrapper.component';
import {HasCountMomentGuard} from './guards/has-count-moment.guard';
import {QuestionnaireLocationPageComponent} from './containers/questionnaire-location-page/questionnaire-location-page.component';
import {QuestionnaireMapPageComponent} from './containers/questionnaire-map-page/questionnaire-map-page.component';
import {AdminPageComponent} from './containers/admin-page/admin-page.component';
import {IsAdminGuard} from './guards/is-admin.guard';
import {WelcomeComponent} from './components/welcome/welcome.component';
import {QuestionnaireMetaPageComponent} from './containers/questionnaire-meta-page/questionnaire-meta-page.component';

const routes: Routes = [
  {path: '', redirectTo: 'welcome', pathMatch: 'full'},
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [NotLoggedInGuard],
    data: {
      title: 'Welkom',
    },
  },
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [NotLoggedInGuard],
    data: {
      title: 'Registreren'
    },
  },
  {
    path: '',
    component: WrapperComponent,
    children: [
      {
        path: 'start',
        component: StartPageComponent,
        data: {
          title: 'Straatvinken'
        },
        canActivate: [LoggedInGuard]
      },
      {
        path: 'tips',
        component: TipsPageComponent,
        canActivate: [LoggedInGuard],
        data: {
          title: 'Tips'
        },
      },
      {
        path: 'about',
        component: AboutPageComponent,
        canActivate: [LoggedInGuard],
        data: {
          title: 'Over Straatvinken'
        },
      },
      {
        path: 'questionnaire-location',
        component: QuestionnaireLocationPageComponent,
        canActivate: [LoggedInGuard],
        data: {
          title: 'Locatie'
        },
      },
      {
        path: 'questionnaire-map',
        component: QuestionnaireMapPageComponent,
        canActivate: [LoggedInGuard, HasCountMomentGuard],
        data: {
          title: 'Kaart'
        },
      },
      {
        path: 'questionnaire-meta',
        component: QuestionnaireMetaPageComponent,
        canActivate: [LoggedInGuard, HasCountMomentGuard],
        data: {
          title: 'Bijkomende vragen'
        },
      },
      {
        path: 'done',
        component: DonePageComponent,
        canActivate: [LoggedInGuard, HasCountMomentGuard],
        data: {
          title: 'Resultaten'
        },
      },
      {
        path: 'admin',
        component: AdminPageComponent,
        canActivate: [LoggedInGuard, IsAdminGuard]
      },
      {
        path: 'practise',
        component: PractisePageComponent,
        canActivate: [LoggedInGuard],
        data: {
          legendAvailable: true,
          title: 'Oefenen'
        },
      },
    ]
  },
  {
    path: 'count',
    component: CountPageComponent,
    canActivate: [LoggedInGuard, HasCountMomentGuard],
    data: {
      title: 'Tellen!'
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
