import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import {environment} from '../../environments/environment';
import * as fromType from './type.reducer';
import * as fromCountMoment from './count-moment.reducer';
import * as fromLocation from './location.reducer';
import * as fromQuestionnaire from './questionnaire.reducer';

import {localStorageSync} from 'ngrx-store-localstorage';

export interface State {
  types: fromType.State;
  countMoments: fromCountMoment.State;
  locations: fromLocation.State;
  questionnaire: fromQuestionnaire.State;
}

export const reducers: ActionReducerMap<State> = {
  types: fromType.reducer,
  countMoments: fromCountMoment.reducer,
  locations: fromLocation.reducer,
  questionnaire: fromQuestionnaire.reducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [localStorageSyncReducer] : [localStorageSyncReducer];

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['countMoments', 'types', 'locations', 'questionnaire'],
    rehydrate: true
  })(reducer);
}

/*
 * TYPES
 */

export const selectTypeState = createFeatureSelector<fromType.State>('types');

export const selectTypeIds = createSelector(
  selectTypeState,
  fromType.selectIds
);
export const selectTypeEntities = createSelector(
  selectTypeState,
  fromType.selectEntities
);
export const selectAllTypes = createSelector(
  selectTypeState,
  fromType.selectAll
);
export const selectTypeTotal = createSelector(
  selectTypeState,
  fromType.selectTotal
);

/*
 * COUNT MOMENTS
 */

export const selectCountMomentState = createFeatureSelector<fromCountMoment.State>('countMoments');

export const selectCountMomentIds = createSelector(
  selectCountMomentState,
  fromCountMoment.selectIds
);
export const selectCountMomentEntities = createSelector(
  selectCountMomentState,
  fromCountMoment.selectEntities
);
export const selectAllCountMoments = createSelector(
  selectCountMomentState,
  fromCountMoment.selectAll
);
export const selectCountMomentTotal = createSelector(
  selectCountMomentState,
  fromCountMoment.selectTotal
);

export const selectCurrentCountMomentId = createSelector(
  selectCountMomentState,
  fromCountMoment.getSelectedCountMomentId
);

export const selectCurrentCountMoment = createSelector(
  selectCountMomentEntities,
  selectCurrentCountMomentId,
  (userEntities, userId) => userEntities[userId]
);

export const dataLoaded = createSelector(
  selectCountMomentTotal,
  selectTypeTotal,
  (countTotal, typeTotal) => countTotal > 0 && typeTotal > 0,
);

/*
 * LOCATIONS
 */

export const selectLocationState = createFeatureSelector<fromLocation.State>('locations');

export const selectAllLocations = createSelector(
  selectLocationState,
  fromLocation.selectAll
);

/*
 * QUESTIONNAIRE
 */

export const selectQuestionnaireState = createFeatureSelector<fromQuestionnaire.State>('questionnaire');

export const selectQuestionnaire = createSelector(
  selectQuestionnaireState,
  fromQuestionnaire.selectAll
);

