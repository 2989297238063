import {Component} from '@angular/core';
import {Platform} from '@ionic/angular';

import {LoadTypes} from './actions/type.actions';
import {Store} from '@ngrx/store';
import * as fromStore from './reducers';
import {LoadActiveCountMoment, LoadCountMoments} from './actions/count-moment.actions';
import {LoadLocations} from './actions/location.actions';
import {AuthService} from './services/auth.service';
import {NotificationsService} from './services/notifications.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {environment} from '../environments/environment';
import {Title} from '@angular/platform-browser';
import {filter, map} from 'rxjs/operators';
import {Angulartics2GoogleAnalytics} from 'angulartics2/ga';

declare let ga: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  preloadImages = [
    '/assets/images/illustrations/after.svg',
    '/assets/images/illustrations/before.svg',
    '/assets/images/illustrations/comfort.svg',
    '/assets/images/illustrations/during.svg',
    '/assets/images/illustrations/location.svg',
    '/assets/images/illustrations/location.svg',
    '/assets/images/illustrations/quality.svg',
    '/assets/images/illustrations/ready.svg',
    '/assets/images/illustrations/welcome.svg',
    '/assets/images/types/bike.svg',
    '/assets/images/types/bus.svg',
    '/assets/images/types/car.svg',
    '/assets/images/types/deliverytruck.svg',
    '/assets/images/types/pedestrian.svg',
    '/assets/images/types/tram.svg',
    '/assets/images/types/truck.svg',
  ];

  constructor(
    private platform: Platform,
    private notifications: NotificationsService,
    private auth: AuthService,
    private store: Store<fromStore.State>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
  ) {

    if (!environment.local) {
      ga('create', environment.gaTrackingCode, 'none');
      angulartics2GoogleAnalytics.startTracking();
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: any) => {
          return {
            urlAfterRedirects: event.urlAfterRedirects,
            activatedRoute: this.activatedRoute
          };
        }),
        map((route) => {
          while (route.activatedRoute.firstChild) {
            route.activatedRoute = route.activatedRoute.firstChild;
          }
          return route;
        }),
        filter((route) => route.activatedRoute.outlet === 'primary'),
      ).subscribe((event: any) => {
      // a slightly modified version of:
      // https://ultimatecourses.com/blog/dynamic-page-titles-angular-2-router-events
      if (event.activatedRoute.data.value.title && event.activatedRoute.data.value.title !== 'Straatvinken') {
        this.title.setTitle(`${event.activatedRoute.data.value.title} | Straatvinken`);
      } else {
        this.title.setTitle(`Straatvinken`);
      }
    });

    this.store.dispatch(new LoadTypes());
    this.store.dispatch(new LoadCountMoments());
    this.store.dispatch(new LoadActiveCountMoment());
    this.store.dispatch(new LoadLocations());

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
    });
  }

  imagesPreloaded() {

  }
}
