import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {CountMoment} from '../models/count-moment.model';

export enum CountMomentActionTypes {
  LoadCountMoments = '[CountMoment] Load CountMoments',
  LoadCountMoment = '[CountMoment] Load CountMoment',
  LoadCountMomentsSuccess = '[CountMoment] Load CountMoments Success',
  LoadCountMomentSuccess = '[CountMoment] Load CountMoment Success',
  LoadCountMomentsError = '[CountMoment] Load CountMoments Error',
  LoadCountMomentError = '[CountMoment] Load CountMoment Error',
  AddCountMoment = '[CountMoment] Add CountMoment',
  UpsertCountMoment = '[CountMoment] Upsert CountMoment',
  AddCountMoments = '[CountMoment] Add CountMoments',
  UpsertCountMoments = '[CountMoment] Upsert CountMoments',
  UpdateCountMoment = '[CountMoment] Update CountMoment',
  UpdateCountMoments = '[CountMoment] Update CountMoments',
  DeleteCountMoment = '[CountMoment] Delete CountMoment',
  DeleteCountMoments = '[CountMoment] Delete CountMoments',
  ClearCountMoments = '[CountMoment] Clear CountMoments',
  SetActiveCountMoment = '[CountMoment] Set Active Count Moment',
}

export class LoadActiveCountMoment implements Action {
  readonly type = CountMomentActionTypes.LoadCountMoment;

  constructor() {
  }
}

export class LoadCountMoments implements Action {
  readonly type = CountMomentActionTypes.LoadCountMoments;

  constructor() {
  }
}

export class LoadCountMomentsSuccess implements Action {
  readonly type = CountMomentActionTypes.LoadCountMomentsSuccess;

  constructor(public payload: { countMoments: CountMoment[] }) {
  }
}

export class LoadCountMomentSuccess implements Action {
  readonly type = CountMomentActionTypes.LoadCountMomentSuccess;

  constructor(public payload: { countMoment: CountMoment }) {
  }
}

export class LoadCountMomentsError implements Action {
  readonly type = CountMomentActionTypes.LoadCountMomentsError;

  constructor() {
  }
}

export class LoadCountMomentError implements Action {
  readonly type = CountMomentActionTypes.LoadCountMomentError;

  constructor() {
  }
}

export class AddCountMoment implements Action {
  readonly type = CountMomentActionTypes.AddCountMoment;

  constructor(public payload: { countMoment: CountMoment }) {
  }
}

export class UpsertCountMoment implements Action {
  readonly type = CountMomentActionTypes.UpsertCountMoment;

  constructor(public payload: { countMoment: CountMoment }) {
  }
}

export class AddCountMoments implements Action {
  readonly type = CountMomentActionTypes.AddCountMoments;

  constructor(public payload: { countMoments: CountMoment[] }) {
  }
}

export class UpsertCountMoments implements Action {
  readonly type = CountMomentActionTypes.UpsertCountMoments;

  constructor(public payload: { countMoments: CountMoment[] }) {
  }
}

export class UpdateCountMoment implements Action {
  readonly type = CountMomentActionTypes.UpdateCountMoment;

  constructor(public payload: { countMoment: Update<CountMoment> }) {
  }
}

export class UpdateCountMoments implements Action {
  readonly type = CountMomentActionTypes.UpdateCountMoments;

  constructor(public payload: { countMoments: Update<CountMoment>[] }) {
  }
}

export class DeleteCountMoment implements Action {
  readonly type = CountMomentActionTypes.DeleteCountMoment;

  constructor(public payload: { id: string }) {
  }
}

export class DeleteCountMoments implements Action {
  readonly type = CountMomentActionTypes.DeleteCountMoments;

  constructor(public payload: { ids: string[] }) {
  }
}

export class ClearCountMoments implements Action {
  readonly type = CountMomentActionTypes.ClearCountMoments;
}

export class SetActiveCountMoment implements Action {
  readonly type = CountMomentActionTypes.SetActiveCountMoment;

  constructor(public payload: { id: number, future: boolean }) {
  }
}

export type CountMomentActions =
  LoadCountMoments
  | LoadActiveCountMoment
  | LoadCountMomentsSuccess
  | LoadCountMomentSuccess
  | LoadCountMomentsError
  | LoadCountMomentError
  | AddCountMoment
  | UpsertCountMoment
  | AddCountMoments
  | UpsertCountMoments
  | UpdateCountMoment
  | UpdateCountMoments
  | DeleteCountMoment
  | DeleteCountMoments
  | ClearCountMoments
  | SetActiveCountMoment;
