import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromStore from '../../reducers';
import {selectAllTypes, selectCurrentCountMoment} from '../../reducers';
import {Type} from '../../models/type.model';
import {TypeService} from '../../services/type.service';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {CountMomentService} from '../../services/count-moment.service';
import * as Sentry from '@sentry/browser';
import {QuestionnaireService} from '../../services/questionnaire.service';
import {UserService} from '../../services/user.service';
import * as aesjs from 'aes-js';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-done-page',
  templateUrl: './done-page.component.html',
  styleUrls: ['./done-page.component.scss']
})
export class DonePageComponent implements OnInit {
  modalShift: {
    car: number;
    public: number;
  } = {
    car: null,
    public: null,
  };

  loading = true;
  hasCounted: boolean;

  currentCountMoment$ = this.store.pipe(select(selectCurrentCountMoment));
  types: Type[];
  shareURL: string;

  private types$ = this.store.pipe(select(selectAllTypes));
  private count$: BehaviorSubject<any> = new BehaviorSubject(null);
  private typesSubscription: Subscription;
  private hasCountedSubscription: Subscription;
  private shareSubscription: Subscription;
  private questionnaireSubscription: Subscription;
  private typesWithCount$: Observable<any>;
  private questionnaire$ = this.questionnaireService.get();
  private hash: string;

  constructor(
    private store: Store<fromStore.State>,
    private router: Router,
    private typeService: TypeService,
    private questionnaireService: QuestionnaireService,
    private countMomentService: CountMomentService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.hasCounted = null;
    this.loading = true;

    this.questionnaireSubscription = this.questionnaire$
      .subscribe((questionnaire: boolean) => {
        if (this.hasCounted && !questionnaire) {
          this.router.navigate(['/questionnaire-location']);
        }
      });

    this.hasCountedSubscription = this.countMomentService.hasCounted()
      .subscribe((hasCounted) => {
        this.hasCounted = hasCounted;

        if (this.hasCounted) {
          this.countMomentService.calculate()
            .then((initialCount) => {
              this.count$.next(initialCount);
            }, (e) => {
              console.error(e);

              Sentry.captureException(new Error(`error calculating: ${e}`));
            });
        } else {
          this.router.navigate(['/start']);
        }
      });

    this.typesWithCount$ = combineLatest([
      this.types$,
      this.count$,
    ]).pipe(filter(([types, count]: [Type[], any]) => !!count))
      .pipe(map(([types, count]: [Type[], any]) => {
        return types.map((type) => {
          return {
            ...type,
            count: count && count[type.slug] ? count[type.slug] : 0,
          };
        });
      }));

    this.typesSubscription = this.typesWithCount$.subscribe((types: any) => {
      this.types = types;

      const modalShift = types.reduce((acc, type) => {
        return {
          car: acc.car + (!type.sustainable ? type.count : 0),
          public: acc.public + (type.sustainable ? type.count : 0),
        };
      }, {car: 0, public: 0});

      const total = modalShift.public + modalShift.car;

      this.modalShift = {
        car: Math.floor(modalShift.car / total * 100),
        public: 100 - Math.floor(modalShift.car / total * 100),
      };

      this.loading = false;
    });

    this.shareSubscription = combineLatest([
      this.typesWithCount$,
      this.currentCountMoment$,
      this.questionnaireService.get(),
      this.userService.getUserDoc().valueChanges(),
    ]).subscribe(([types, currentCountMoment, questionnaire, user]) => {
      const countMapped = types ? types.map((type) => ({[type.slug]: type.count})) : [];
      const count = Object.assign({}, ...countMapped);

      const data = {
        count: {
          ...count,
        },
        id: currentCountMoment ? currentCountMoment.id : null,
        name: currentCountMoment ? currentCountMoment.name : null,

        start_utc: currentCountMoment ? new Date(currentCountMoment.start_time).getTime() / 1000 : null, // unix timestamp
        end_utc: currentCountMoment ? new Date(currentCountMoment.end_time).getTime() / 1000 : null, // unix timestamp

        questionnaire: {
          city: questionnaire ? questionnaire.city : null,
          continuous: questionnaire ? questionnaire.continuous : null,
          houseNr: questionnaire ? questionnaire.houseNr : null,
          oneWay: questionnaire ? questionnaire.oneWay : null,
          street: questionnaire ? questionnaire.street : null,
        },
        user: {email: user ? user.email : null},
      };

      const key = aesjs.utils.hex.toBytes('4a586e6747784a466459726539457161734c6e4c72564241');
      const textBytes = aesjs.utils.utf8.toBytes(JSON.stringify(data));
      const encryptedBytes = new aesjs.ModeOfOperation.ecb(key).encrypt(aesjs.padding.pkcs7.pad(textBytes));

      this.hash = aesjs.utils.hex.fromBytes(encryptedBytes);
      this.shareURL = environment.production ? 'https://straatvinken.be/resultaten-delen?hash=' + this.hash
        : 'https://staging.straatvinken.be/resultaten-delen?hash=' + this.hash;

    });
  }

  ionViewWillLeave() {
    this.shareSubscription.unsubscribe();
    this.typesSubscription.unsubscribe();
    this.hasCountedSubscription.unsubscribe();
    this.questionnaireSubscription.unsubscribe();
  }

  goToExternal(url) {
    window.open(url, '_blank');
  }
}
