import {NgModule} from '@angular/core';
import {MaxPipe} from './max/max';
import {TimeToTextPipe} from './time-to-text/time-to-text';
import {MinPipe} from './min/min';
import {NamePipe} from './name/name.pipe';
import { SortDatePipe } from './sort-date/sort-date.pipe';
import {MomentPipe} from './moment/moment.pipe';

@NgModule({
  declarations: [
    MaxPipe,
    TimeToTextPipe,
    MinPipe,
    MomentPipe,
    NamePipe,
    SortDatePipe,
  ],
  imports: [],
  exports: [
    SortDatePipe,
    MaxPipe,
    TimeToTextPipe,
    MomentPipe,
    MinPipe,
    NamePipe
  ]
})
export class PipesModule {
}
