import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MenuController, PopoverController} from '@ionic/angular';
import {filter, take} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {AdminService} from '../../services/admin.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {ClearCountMoments, SetActiveCountMoment} from '../../actions/count-moment.actions';
import {Store} from '@ngrx/store';
import * as fromStore from '../../reducers';
import {environment} from '../../../environments/environment';
import {LegendComponent} from '../../components/legend/legend.component';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent {
  legendAvailable: boolean;
  title = null;
  isDevelopment = !environment.production;
  backButton: boolean;
  isAuthenticated$: Observable<boolean>;
  isAdmin$: Observable<boolean>;

  private routerEventSubscription: Subscription;
  private routerSubscription: Subscription;

  constructor(
    private menu: MenuController,
    private route: ActivatedRoute,
    private router: Router,
    private afAuth: AngularFireAuth,
    private authService: AuthService,
    private adminService: AdminService,
    private store: Store<fromStore.State>,
    private popoverController: PopoverController,
  ) {
  }

  ionViewWillEnter() {
    this.isAuthenticated$ = this.authService.isAuthenticatedObservable();

    this.routerSubscription = this.route.queryParams.subscribe(params => this.backButton = params['backButton']);

    this.setTitle();

    this.routerEventSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((val) => this.setTitle());

    this.isAdmin$ = this.adminService.isAdmin();
  }

  ionViewWillLeave() {
    this.routerEventSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  setTitle() {
    if (this.route.root && this.route.root.firstChild && this.route.root.firstChild.firstChild) {
      this.route.root.firstChild.firstChild.data
        .pipe(take(1))
        .subscribe((data) => {
          if (data) {
            this.title = data.title;
            this.legendAvailable = data.legendAvailable;
          }
        });
    }
  }

  closeMenu() {
    this.menu.close();
  }

  async logout() {
    this.store.dispatch(new SetActiveCountMoment({id: null, future: false}));
    this.store.dispatch(new ClearCountMoments());
    await this.authService.logout();
    await this.router.navigate(['/welcome']);
    location.reload();
  }

  back() {
    window.history.back();
  }

  async presentLegend() {
    const popover = await this.popoverController.create({
      component: LegendComponent,
      cssClass: 'legend-popover',
      translucent: true,
      backdropDismiss: true,
    });
    return await popover.present();
  }
}
