import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromStore from '../../reducers';
import {AdminService} from '../../services/admin.service';
import {Observable} from 'rxjs';
import {CountMoment} from '../../models/count-moment.model';
import {selectAllCountMoments} from '../../reducers';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.css']
})
export class AdminPageComponent implements OnInit {
  notifications$ = this.adminService.getNotifications();
  countMoments$: Observable<CountMoment[]> = this.store.select(selectAllCountMoments);

  notification: {
    title?: string;
    message?: string;
    date: string;
    countMomentId?: string;
  } = {
    date: moment().seconds(0).milliseconds(0).format(),
  };

  constructor(private store: Store<fromStore.State>, private adminService: AdminService) {
  }

  ngOnInit() {
  }

  async addNotification(form) {
    await this.adminService.createNotifications(form.value);

    this.notification = {
      date: moment().seconds(0).milliseconds(0).format(),
    };
  }

  removeNotification(notificationId: string) {
    this.adminService.removeNotification(notificationId);
  }
}
