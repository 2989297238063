import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {combineLatest, Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {selectAllTypes} from '../reducers';
import * as fromStore from '../reducers';
import {CountMomentService} from './count-moment.service';
import {Type} from '../models/type.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TypeService {
  constructor(
    private http: HttpClient,
    private store: Store<fromStore.State>,
    private countMomentService: CountMomentService
  ) {
  }

  getAll() {
    return this.http.get(
      `${environment.apiUrl}type`
    );
  }

  getWithCount(): Observable<Type[]> {
    return combineLatest(
      this.store.pipe(select(selectAllTypes)),
      this.countMomentService.get(),
    ).pipe(map(([types, countMoment]: [Type[], any]) => {
      return types.map((type) => {
        return {
          ...type,
          count: countMoment && countMoment[type.slug] ? countMoment[type.slug] : 0,
        };
      });
    }));
  }
}
