<ion-content>
  <div class="container">
    <ng-container *ngIf="activeCategory === 'all'">
      <h2 class="ion-no-margin">Tips</h2>
      <ion-card class="ion-padding" [routerLink]="'/tips'" [queryParams]="{tip: 'location', backButton: true}">
        <div>
          <h2 class="ion-no-margin">Waar tellen?</h2>
          <img [src]="'assets/images/illustrations/location.svg'" alt="">
        </div>
      </ion-card>
      <ion-card class="ion-padding" [routerLink]="'/tips'" [queryParams]="{tip: 'quality', backButton: true}">
        <div>
          <h2 class="ion-no-margin">Hoe tellen?</h2>
          <img [src]="'assets/images/illustrations/quality.svg'" alt="">
        </div>
      </ion-card>
      <ion-card class="ion-padding" [routerLink]="'/tips'" [queryParams]="{tip: 'comfort', backButton: true}">
        <div>
          <h2 class="ion-no-margin">Comfortabel tellen</h2>
          <img [src]="'assets/images/illustrations/comfort.svg'" alt="">
        </div>
      </ion-card>
    </ng-container>

    <ion-list lines="none" *ngIf="activeCategory === 'location'">
      <h2 class="ion-no-margin ion-margin-bottom">Waar tellen?</h2>
      <app-accordion [title]="'Tel waar het verkeer het traagst verloopt'"
                     [text]="'Tel je in een drukke straat? Tel dan op een plaats waar het verkeer het traagst is (niet op een kruispunt of rotonde).'">
      </app-accordion>

      <app-accordion [title]="'Vermijd bus- en tramhaltes'"
                     [text]="'Vermijd bus- en tramhaltes, want de in- en uitstapbewegingen kunnen de telling vertekenen. Dat geldt ook voor grote parkings.'">
      </app-accordion>

      <app-accordion [title]="'Tel eventueel per twee'"
                     [text]="'Tel je in een drukke straat (bv. 2 rijvakken per richting)? Doe dat dan per twee: jij één richting en een tweede straatvink de andere richting.'">
      </app-accordion>

      <app-accordion [title]="'Respecteer de coronamaatregelen'"
                     [text]="'Tel met respect voor de corona-maatregelen van het moment (bv. op het vlak van social distancing)'">
      </app-accordion>
    </ion-list>

    <ion-list lines="none" *ngIf="activeCategory === 'comfort'">
      <h2 class="ion-no-margin ion-margin-bottom">Comfortabel tellen</h2>
      <app-accordion [title]="'Denk aan je rug'"
                     [text]="'Zoek een comfortabele plaats, liefst met rugsteun.'">
      </app-accordion>

      <app-accordion [title]="'Niets of niemand ontsnapt aan je aandacht'"
                     [text]="'Als je staat, heb je een goed overzicht.'">
      </app-accordion>

      <app-accordion [title]="'‘Sorry, nu niet, ik ben aan het tellen’'"
                     [text]="'Telefoneer niet tijdens het tellen.'">
      </app-accordion>

      <app-accordion [title]="'Hou gesprekken kort'"
                     [text]="'Hou gesprekken met nieuwsgierige voorbijgangers kort: ‘Wij meten het verkeer. Kijk op www.straatvinken.be voor meer uitleg en de resultaten.’'">
      </app-accordion>
    </ion-list>

    <ion-list lines="none" *ngIf="activeCategory === 'quality'">
      <h2 class="ion-no-margin ion-margin-bottom">Hoe tellen?</h2>
      <app-accordion [title]="'Wat moet ik juist tellen?'"
                     [text]="'We tellen alle vervoersmiddelen, behalve de metro, trein, waterbus en het luchtverkeer. Vuistregel: we tellen het aantal ‘voertuigen’. Een bakfiets met een ouder en twee kinderen tellen we als één voertuig (fiets). Uitzondering: een persoon met een kinderwagen tellen we als 2 voetgangers. Op het telscherm kan je een legende terugvinden voor welke voortuigen bij welk icoon horen.'">
      </app-accordion>

      <app-accordion [title]="'Het tellen is aan de snellen'"
                     [text]="'Tel eerst de snellere weggebruikers, dan de tragere.'">
      </app-accordion>

      <app-accordion [title]="'Werk met een doorsnede'"
                     [text]="'Tel alles wat bij jou passeert (een doorsnede van de straat).'">
      </app-accordion>

      <app-accordion [title]="'Eén of twee rijrichtingen?'"
                     [text]="'Tel je in een drukke straat en ben je alleen? Kies dan om consequent één rijrichting te tellen. Na afloop kan je aangeven of je in één of meerdere richtingen geteld hebt.'">
      </app-accordion>
    </ion-list>
  </div>

</ion-content>
