<app-splash-screen></app-splash-screen>

<div class="ion-padding wrapper">
  <div class="content">
    <div class="logo-wrapper">
      <img width="160px"
           class="logo"
           [src]="'assets/images/logo-header-dark.svg'"
           [srcset]="'assets/images/logo-header-dark.svg 2x'"
           alt="Straatvinken"/>
    </div>
    <img [src]="'assets/images/illustrations/welcome.svg'" class="slide-image" alt=""/>
    <div class="no-shrink">
      <h2 class="ion-no-margin ion-margin-bottom slide-title">Welkom op de tel-tool van Straatvinken!</h2>
      <p>
        Met deze tool kan je het verkeer tellen tijdens Straatvinken. Op voorhand kan je ook al oefenen om hem te
        leren gebruiken.
      </p>
    </div>
  </div>

  <div class="steps">
    <app-steps [steps]="2" [active]="0"></app-steps>
    <ion-button [routerLink]="'/login'">
      Volgende
    </ion-button>
  </div>
</div>
