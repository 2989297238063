<ion-content *ngIf="questionnaire$ | async as questionnaire">
  <form class="flex" [formGroup]="locationForm" (ngSubmit)="onSubmit()">
    <div>
      <ion-item lines="none" class="ion-no-padding">
        <ion-searchbar #searchbar
                       class="ion-no-padding"
                       inputmode="search"
                       placeholder="Geef hier je tellocatie in"
                       formControlName="search"
        ></ion-searchbar>
      </ion-item>

      <div *ngIf="!hasSelectedAddress">
        <div *ngIf="isSearching && autocompleteItems.length === 0 && !hasSelectedAddress">
          <h2>Oepss..</h2>
          <p>Dit adres werd niet gevonden.</p>
          <div class="not-found ion-margin-top">
            <img [src]="'assets/images/illustrations/notfound.svg'" alt="">
          </div>
        </div>

        <ion-list
          class="ion-no-padding"
          [hidden]="autocompleteItems.length == 0 || locationForm.get('search').value == ''">
          <ion-item
            class="ion-no-padding"
            lines="none">
            <ion-list>
              <ion-item *ngFor="let item of autocompleteItems"
                        tappable
                        class="ion-no-padding"
                        (click)="selectSearchResultItem(item)">
                {{ item.description }}
              </ion-item>
            </ion-list>
          </ion-item>
        </ion-list>
      </div>
    </div>

    <div *ngIf="!hasSelectedAddress" [ngClass]="!isSearching ? 'counted-done' : 'hide'">
      <h2 *ngIf="!isSearching"
          class="ion-no-margin ion-margin-bottom">Goed geteld!</h2>

      <p *ngIf="!isSearching">
        Nu nog je locatie ingeven. Geef zorgvuldig de plaats aan waar je. geteld hebt: <b>de straat, het huisnummer en de gemeente of stad</b>.
      </p>
    </div>
    <div>
    </div>

    <p *ngIf="hasSelectedAddress">
      Indien de locatie op de kaart niet overeenkomt met jouw meetlocatie, kan je hier <b>de kaart
      of het pinnetje
      verschuiven.</b>
    </p>

    <div *ngIf="hasSelectedAddress" class="map">
      <mgl-map
        [style]="'mapbox://styles/mapbox/streets-v11'"
        zoom="18"
        [center]="questionnaire.coordinates">
        <mgl-marker [lngLat]="questionnaire.coordinates"
                    [draggable]="true"
                    (markerDragEnd)="markerDrag($event)">
          <div class="marker"></div>
        </mgl-marker>
      </mgl-map>
    </div>

    <div>
      <div class="steps">
        <app-steps [steps]="2" [active]="0">
        </app-steps>

        <ion-button expand="block"
                    [disabled]="!hasSelectedAddress"
                    [type]="'submit'">
          Volgende
          <ion-spinner *ngIf="loading"></ion-spinner>
        </ion-button>
      </div>
    </div>
  </form>
</ion-content>