import {Component, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromStore from '../../reducers';
import {selectQuestionnaire} from '../../reducers';
import {MapService} from 'ngx-mapbox-gl';

@Component({
  selector: 'app-questionnaire-map-page',
  templateUrl: './questionnaire-map-page.component.html',
  styleUrls: ['./questionnaire-map-page.component.scss']
})
export class QuestionnaireMapPageComponent implements OnInit {
  questionnaire$ = this.store.pipe(select(selectQuestionnaire));
  city: string;
  street: string;
  houseNr: string;

  coordinates = {lat: 51.2194475, lng: 4.4024643};

  constructor(private store: Store<fromStore.State>, private mapService: MapService) {
  }

  ngOnInit() {

  }

  ionViewWillEnter() {
    this.questionnaire$.subscribe(({city, street, houseNr, coordinates}) => {
      this.city = city;
      this.street = street;
      this.houseNr = houseNr;
      this.coordinates = coordinates;
    });
  }

  mapDrag($event: any) {
    // Ideaal gezien blijft de marker centraal staan op de kaart
    // wanneer de gebruiker de kaart versleept.
    //
    // Ik heb enkel nog niet gevonden hoe ik de center coordinaten
    // van de kaart kan ophalen uit dit $event.
  }


  markerDrag($event: mapboxgl.Marker) {
    // Als het niet lukt om de marker centraal op de kaart te laten staan
    // tijdens het slepen, kunnen we de gebruiker ook gewoon de marker
    // zelf laten verslepen. In dat geval kan deze event gebruikt worden.

    // const markerCoordinates = $event.getLngLat();
  }
}
