import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {TypeService} from '../services/type.service';

import {LoadTypes, TypeActionTypes, LoadTypesSuccess, LoadTypesError} from '../actions/type.actions';
import {Type} from '../models/type.model';

@Injectable()
export class TypeEffects {
  constructor(private actions$: Actions, private typeService: TypeService) {
  }

  @Effect()
  fetch$ = this.actions$
    .pipe(
      ofType<LoadTypes>(TypeActionTypes.LoadTypes),
      mergeMap((action: LoadTypes) => this.typeService.getAll()
        .pipe(
          map((response: Type[]) => new LoadTypesSuccess({types: response})),
          catchError((response: any) => {
            console.error(response);
            return of(new LoadTypesError());
          })
        ))
    );

  // todo: on fail, wait 2 seconds retry again
}
