import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromStore from '../reducers';
import {dataLoaded, selectCurrentCountMoment} from '../reducers';
import {filter, map, take, tap, withLatestFrom} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HasCountMomentGuard implements CanActivate {
  constructor(private store: Store<fromStore.State>, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    return this.store.pipe(select(dataLoaded))
      .pipe(filter((loaded) => loaded))
      .pipe(withLatestFrom(this.store.pipe(select(selectCurrentCountMoment))))
      .pipe(map(([loaded, currentCountMoment]) => loaded && !!currentCountMoment))
      .pipe(take(1))
      .toPromise()
      .then((hasCountMoment) => {
        if (!hasCountMoment) {
          this.router.navigate(['/start']);
        }

        return hasCountMoment;
      });
  }
}
