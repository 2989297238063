import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output, ViewChild
} from '@angular/core';

import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'type-count',
  templateUrl: 'type-count.html',
  styleUrls: ['./type-count.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('ripple', [
      state('void', style({
        transform: 'scale(2)',
        visibility: 'hidden',
        opacity: '0.9',
      })),
      state('end', style({
        transform: 'scale(10)',
        'visibility': 'visible',
        opacity: '0',
      })),
      transition('void => end', animate('1000ms cubic-bezier(0.215, 0.61, 0.355, 1)')),
    ]),
  ]
})
export class TypeCountComponent {
  @Input() name: string;
  @Input() slug: string;
  @Input() color: string;
  @Input() id: number;
  @Input() count: number;

  @Output() add = new EventEmitter<string>();
  @Output() remove = new EventEmitter<string>();

  @ViewChild('container', { static: true }) container;
  @ViewChild('ripple') ripple: ElementRef;

  ripples = [];

  private clearTimeout: any;

  constructor(private detector: ChangeDetectorRef) {
  }

  animateAndAdd(e) {
    this.animate(e, 100);
    this.add.emit(this.slug);
  }

  animateAndRemove(e) {
    this.animate(e, -100);
    this.remove.emit(this.slug);
  }

  ionViewWillLeave() {
    clearTimeout(this.clearTimeout);
  }

  animate(e, offset) {
    clearTimeout(this.clearTimeout);

    const vx = e.pageX - (this.container.nativeElement.offsetLeft + 50) + offset;
    const vy = e.pageY - (this.container.nativeElement.offsetTop + 50);

    const i = Math.random();

    const ripple = {
      i,
      state: 'void',
      top: vy + 'px',
      left: vx + 'px',
    };

    this.ripples.push(ripple);

    requestAnimationFrame(() => {
      ripple.state = 'end';
      this.detector.detectChanges();
    });

    this.clearTimeout = setTimeout(() => {
      this.ripples = [];

      if (!this.detector['destroyed']) {
        this.detector.detectChanges();
      }
    }, 2000);
  }

  trackByFn(index, item) {
    return index;
  }
}
