import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Type } from '../models/type.model';
import { TypeActions, TypeActionTypes } from '../actions/type.actions';
import {selectCountMomentTotal} from './index';

export interface State extends EntityState<Type> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Type> = createEntityAdapter<Type>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: TypeActions
): State {
  switch (action.type) {
    case TypeActionTypes.AddType: {
      return adapter.addOne(action.payload.type, state);
    }

    case TypeActionTypes.UpsertType: {
      return adapter.upsertOne(action.payload.type, state);
    }

    case TypeActionTypes.AddTypes: {
      return adapter.addMany(action.payload.types, state);
    }

    case TypeActionTypes.UpsertTypes: {
      return adapter.upsertMany(action.payload.types, state);
    }

    case TypeActionTypes.UpdateType: {
      return adapter.updateOne(action.payload.type, state);
    }

    case TypeActionTypes.UpdateTypes: {
      return adapter.updateMany(action.payload.types, state);
    }

    case TypeActionTypes.DeleteType: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TypeActionTypes.DeleteTypes: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TypeActionTypes.LoadTypesSuccess: {
      return adapter.addAll(action.payload.types, state);
    }

    case TypeActionTypes.ClearTypes: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
