import {Component, Input} from '@angular/core';

/**
 * Generated class for the SpreadComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'spread',
  styleUrls: ['./spread.scss'],
  templateUrl: 'spread.html'
})
export class SpreadComponent {
  @Input() fill;

  constructor() {
  }
}
