<ion-content>
  <div class="wrapper">
    <div class="warning ion-margin-start">
      <h2>Oefenen</h2>
      <span>Deze resultaten worden niet bijgehouden.</span>
    </div>
    <div class="count">
      <type-count *ngFor="let type of (types$ | async);trackBy:identify"
                  [name]="type.name"
                  [slug]="type.slug"
                  [id]="type.id"
                  [color]="type.color"
                  [count]="type.count"
                  (add)="addToCount($event)"
                  (remove)="removeFromCount($event)">
      </type-count>
      <div class="hidden-md spacer"></div>
    </div>
  </div>
</ion-content>
