<ion-grid class="ion-no-padding">
  <ion-row class="progress ion-justify-content-between">
    <ion-col class="progress-icon ion-no-padding" size="auto">
      <ion-icon class="progress-icon-img"
                [name]="getMotivationalMessage(progress?.per).icon"
                [color]="'dark'"
                size="small"></ion-icon>

      <p class="ion-margin progress-title">Nog {{progress?.timeLeft | timeToText }}!
        {{ getMotivationalMessage(progress?.per).message }}</p>
    </ion-col>
    <ion-col class="progress-icon" size="1">
      <ion-buttons>
        <ion-button (click)="presentLegend()"
                    [color]="'primary'">
          <ion-icon name="information-circle" class="legend-icon" size="large"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-col>
  </ion-row>
</ion-grid>

<div class="progress-outer"
     *ngIf="progress">
  <div class="progress-inner ion-no-padding"
       [style.width]="(progress?.per | max:100) + '%'">
  </div>
</div>